import { Coordinate } from "../../helpers/coordinate";

export enum PuzzleType {
  STANDARD,
  COUNTER,
  END,
  START,
  LANGUAGE_SELECTOR,
  GPS_TRACKER
}

export type PuzzleBase = {
  id: number;
  type: PuzzleType;
  shortTitle: string;
  title: string;
  description: string;
};

export type PuzzleStandard = PuzzleBase & {
  images: Array<any>;
  solutionRegExp: RegExp;
  solutionKey: string;
  hints: Array<Hint>;
};

export type GpsTrackerPuzzle = PuzzleBase & {
  images: Array<any>;
  destination: Coordinate & {
    distance: number; // In meter.
  }
};

export type PuzzleCounter = PuzzleBase & {
  amount: number;
  images: Array<any>;
};

export type PuzzleEnd = PuzzleBase & {
  url: string;
};

export type PuzzleStart = {
  id: number;
  type: PuzzleType;
};

export type Puzzle = PuzzleStandard | PuzzleCounter | PuzzleEnd | PuzzleStart | GpsTrackerPuzzle;

export type Hint = {
  id: number;
  text: string;
  imgUrl?: any;
};

export type Tour = {
  id: number;
  name: string;
  description: string;
  puzzles: Array<Puzzle>;
};

export type TranslatedTour = {
  id: number,
  hu: Tour,
  en: Tour
}