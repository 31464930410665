import React, { useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { Box, TextField, Typography } from "@material-ui/core";
import { I18nService } from "../services/i18n-service";
import { Coordinate, calculateBearing } from "../helpers/coordinate";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
    },
    arrowHolder: {
      display: 'block',
      align: 'center',
      width: '100%',
      paddingTop: '10px',
      paddingBottom: '10px',
    },
    arrow: {
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '70%',
      height: '70%'
    },
    data: {
      display: 'block'
    }
  })
);

type CompassProps = {
  startingPoint: Coordinate,
  destination: Coordinate
};

const Compass: React.FC<CompassProps> = ({
  startingPoint,
  destination
}: CompassProps) => {
  const classes = useStyles({});

  const [orientation, setOrientation] = useState<DeviceOrientationEvent>(null);

  if (typeof DeviceOrientationEvent['requestPermission'] === 'function') {
    DeviceOrientationEvent['requestPermission']().then(permissionState => {
      if (permissionState === 'granted') {
        window.addEventListener('deviceorientation', (e) => {
          setOrientation(e);
        });
      }
    })
    .catch(console.error);
  } else {
    window.addEventListener('deviceorientationabsolute', (e) => {
      setOrientation(e as DeviceOrientationEvent);
    });
    /** else {
    window.addEventListener('deviceorientationabsolute', (event) => {
    this.currentCompass$.next(360 - Math.round(event['alpha']));
    }, true);
    }*/
  }

  /**window.addEventListener('deviceorientation', function(e) {
    setOrientation(e);
  }, false);*/

  return (
    <Box className={classes.root}>
      <Box className={classes.arrowHolder}>
        <img className={classes.arrow} src={require("../assets/images/arrow_blue.png")} style={{
          transform: 'rotate(' + ((orientation != null ? orientation.alpha : 0) + calculateBearing(startingPoint, destination)) + 'deg)'
        }}></img>
      </Box>
      <Box className={classes.data}>
        <Typography
          variant="body1"
          color="textSecondary"
          component="p"
        >
          Irány:<br/>
          Alpha: {orientation != null ? orientation.alpha : 0},<br/>
          Bearing: {calculateBearing(startingPoint, destination)}
          Arrow direction: {(orientation != null ? orientation.alpha : 0) + calculateBearing(startingPoint, destination)}
        </Typography>
      </Box>
    </Box>
  );
};

export default Compass;
