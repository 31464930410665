import { useSelector, useDispatch } from "react-redux";
import React, { useEffect } from "react";
import VoucherScreen from "./screens/VoucherScreen";
import PuzzleScreen from "./screens/PuzzleScreen";
import { API_SESSION_FETCH } from "./redux/api/apiActionTypes";
import { loadSession } from "./redux/api/apiActions";

const Router: React.FC = () => {
  const hasSession = useSelector(
    (state) => state.apiReducer.done && !state.apiReducer.error
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadSession());
  }, []);

  if (hasSession) {
    return <PuzzleScreen />;
  } else {
    return <VoucherScreen />;
  }
};

export default Router;
