import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  Box, Button,
} from "@material-ui/core";
import { setUserData } from "../../redux/api/apiActions";
import { I18nService } from "../../services/i18n-service";
import ImageCarousel from "../../components/ImageCarousel";
import { GpsTrackerPuzzle } from "../../assets/tours/types";
import { calculateDistance } from "../../helpers/coordinate";
import Compass from "../../components/Compass";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      margin: theme.spacing(1),
      padding: theme.spacing(1),
      flexGrow: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start"
    },
    footer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column"
    },
    text: {
      whiteSpace: "pre-line"
    },
    helpButton: {
      marginBottom: theme.spacing(2)
    },
    hr: {
      height: 1,
      backgroundColor: "#fff",
      opacity: 0.5,
      width: 200,
      margin: theme.spacing(2)
    }
  })
);

type GpsTrackerProp = {
  puzzle: GpsTrackerPuzzle;
  onComplete: Function;
};

const GpsTracker: React.FC<GpsTrackerProp> = ({ puzzle, onComplete }) => {
  const classes = useStyles({});

  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [distance, setDistance] = useState(Infinity);

  let updateInterval: NodeJS.Timeout | undefined = undefined;

  const updateDistance = () => {
    console.log('Getting GPS coordinate.');
    const success = (position) => {
      setLatitude(position.coords.latitude);
      setLongitude(position.coords.longitude);
      setDistance(Math.floor(calculateDistance({
        lat: position.coords.latitude,
        lng: position.coords.longitude
      }, puzzle.destination)));
    }
    
    const error =() => {
      console.log("Unable to retrieve your location");
    }
    navigator.geolocation.getCurrentPosition(success, error);
  };

  if (navigator.geolocation) {
    console.log('Geolocation supported.');
    updateDistance();
    if (updateInterval !== undefined){
      clearInterval(updateInterval);
    }
    updateInterval = setInterval(updateDistance, 1000);
  } else {
    console.log("Geolocation not supported");
    // Should skip to the next puzzle.
  }
  return (
    <Fragment>
      <Box className={classes.content}>
        <Typography
          variant="h5"
          color="primary"
          component="h2"
        >
          {puzzle.title}
        </Typography>
        <Typography
          variant="body1"
          color="textSecondary"
          component="p"
        >
          {puzzle.description}
        </Typography>
        <Typography
          variant="body1"
          color="textSecondary"
          component="p"
        >
          {new I18nService().getTranslationFor('distance')}: {distance} {new I18nService().getTranslationFor('meters')}
        </Typography>
        <Compass destination={puzzle.destination} startingPoint={{
          lat: latitude,
          lng: longitude
        }}></Compass>
      {distance <= puzzle.destination.distance && <Button
        size="large"
        variant="contained"
        onClick={(e) => {
          onComplete();
        }}
      >
        {new I18nService().getTranslationFor('submit')}
      </Button>}
      </Box>
    </Fragment>
  );
};

export default GpsTracker;
