import hu from "../i18n/hu.json";
import en from "../i18n/en.json";

export class I18nService {
  _storageKey: string = "language";
  _language: string;
  _languages: any = {
    hu: hu,
    en: en,
  };

  getLanguage() {
    if (this._language === undefined) {
      this._language = localStorage.getItem(this._storageKey);
    }
    if (this._language === null || this._language === "undefined") {
      this._language = this.getDefaultLanguage();
    }
    return this._language;
  }

  setLanguage(languageKey: string) {
    if (!this.languageExists(languageKey)) {
      return;
    }
    localStorage.setItem(this._storageKey, languageKey);
  }

  getPossibleLanguages(): { language: string; image: string }[] {
    return [
      {
        language: "hu",
        image: "https://vectorflags.s3.amazonaws.com/flags/hu-circle-01.png",
      },
      {
        language: "en",
        image: "https://vectorflags.s3.amazonaws.com/flags/uk-circle-01.png",
      },
    ];
  }

  getDefaultLanguage(): string {
    const languages = this.getPossibleLanguages();
    if (languages.length > 0) {
      return languages[0].language;
    } else {
      return "hu";
    }
  }

  languageExists(languageKey: string): boolean {
    return (
      this.getPossibleLanguages().find(
        (language) => language.language === languageKey
      ) !== null
    );
  }

  getTranslationFor(
    i18nKey: string,
    parameters: { [key: string]: string } = {}
  ) {
    const language = this.getLanguage();
    const translationExists = this._languages[language][i18nKey] !== undefined;
    if (translationExists) {
      let output: string = this._languages[language][i18nKey];
      for (let key in parameters) {
        output = output.replace(new RegExp(`{{${key}}}`, "g"), parameters[key]);
      }
      return output;
    } else {
      return `<<${i18nKey}>>`;
    }
  }
}
