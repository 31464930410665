import { createLogicMiddleware } from 'redux-logic';
import rootReducer from './rootReducer';
import arrLogic from './rootLogic';
import { applyMiddleware, createStore } from 'redux';

const deps = { // optional injected dependencies for logic
  // anything you need to have available in your logic
};

const logicMiddleware = createLogicMiddleware(arrLogic, deps);

logicMiddleware.monitor$.subscribe(
    x => console.log(x)
  );

const middleware = applyMiddleware(
  logicMiddleware
);

const enhancer = middleware; // could compose in dev tools too

export default function configureStore() {
  const store = createStore(rootReducer, enhancer);
  return store;
}
