import gql from "graphql-tag";

export const GET_SESSION = gql`
  query GET_SESSION($voucherCode: String!) {
    Sessions(where: { voucherCode: { _eq: $voucherCode } }) {
      createdAt
      voucherCode
      tourId
      puzzleId
      id
      hintCount
      missedCount
      numberOfPlayers
      startedAt
      finishedAt
    }
  }
`;

export const SOLVE_PUZZLE = gql`
  mutation SOLVE_PUZZLE($voucherCode: String!) {
    update_Sessions(
      where: { voucherCode: { _eq: $voucherCode } }
      _inc: { puzzleId: 1 }
    ) {
      affected_rows
      returning {
        puzzleId
      }
    }
  }
`;

export const SET_PUZZLE = gql`
  mutation setPuzzle($voucherCode: String!, $puzzleId: Int!) {
    update_Sessions(
      where: { voucherCode: { _eq: $voucherCode } }
      _set: { puzzleId: $puzzleId }
    ) {
      affected_rows
      returning {
        id
        puzzleId
      }
    }
  }
`;

export const LOG_ANSWER = gql`
  mutation logAnswer($answer: String!, $puzzleId: Int!, $sessionId: Int!) {
    insert_Answers(
      objects: { answer: $answer, puzzleId: $puzzleId, sessionId: $sessionId }
    ) {
      affected_rows
    }
  }
`;

export const SET_FINISHED_AT = gql`
  mutation SetFinishedAt($sessionId: Int!, $finishedAt: timestamp!) {
    update_Sessions(
      where: { id: { _eq: $sessionId } }
      _set: { finishedAt: $finishedAt }
    ) {
      returning {
        hintCount
        missedCount
        numberOfPlayers
        startedAt
        finishedAt
      }
    }
  }
`;

export const INCREMENT_HINT_COUNT = gql`
  mutation IncrementHintCount($sessionId: Int!) {
    update_Sessions(
      where: { id: { _eq: $sessionId } }
      _inc: { hintCount: 1 }
    ) {
      returning {
        hintCount
        missedCount
        numberOfPlayers
        startedAt
        finishedAt
      }
    }
  }
`;

export const INCREMENT_MISSED_COUNT = gql`
  mutation IncrementMissedCount($sessionId: Int!) {
    update_Sessions(
      where: { id: { _eq: $sessionId } }
      _inc: { missedCount: 1 }
    ) {
      returning {
        hintCount
        missedCount
        numberOfPlayers
        startedAt
      }
    }
  }
`;

export const SET_USER_DATA = gql`
  mutation SetUserData($sessionId: Int!, $numberOfPlayers: Int!, $email: String!, $address: String!, $taxNumber: String!, $hasNewsletter: Boolean!, $startedAt: timestamp!) {
    update_Sessions(
      where: { id: { _eq: $sessionId } }
      _set: { 
        numberOfPlayers: $numberOfPlayers,
        email: $email,
        address: $address,
        taxNumber: $taxNumber,
        hasNewsletter: $hasNewsletter,
        startedAt: $startedAt
       }
    ) {
      returning {
        hintCount
        missedCount
        numberOfPlayers
        startedAt
        finishedAt
      }
    }
  }
`;
