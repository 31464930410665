import React, { useState, Fragment } from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Box, Button } from "@material-ui/core";
import ImageCarousel from "../../components/ImageCarousel";
import Countdown from "react-countdown-now";
import { PuzzleCounter } from "../../assets/tours/types";
import { I18nService } from "../../services/i18n-service";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      height: "100vh"
    },
    header: {},
    content: {
      margin: theme.spacing(1),
      padding: theme.spacing(1),
      flexGrow: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start"
    },
    button: {
      alignSelf: "stretch"
    },
    counter: {
      alignSelf: "center",
      marginTop: theme.spacing(4)
    },
    footer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: theme.spacing(2)
    }
  })
);

type CounterPuzzleProps = {
  puzzle: PuzzleCounter;
  onComplete: Function;
};

const CounterPuzzle: React.FC<CounterPuzzleProps> = ({
  puzzle,
  onComplete
}) => {
  const classes = useStyles({});

  const [counterFinished, setCounterFinished] = useState(false);
  const [counterDate, setCounterDate] = useState(
    Date.now() + puzzle.amount * 1000
  );

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      setCounterFinished(true);
      return (
        <Typography
          className={classes.counter}
          variant="h2"
          color="primary"
          component="h1"
        >
          {new I18nService().getTranslationFor('timeIsUp')}
        </Typography>
      );
    } else {
      return (
        <Typography
          className={classes.counter}
          variant="h2"
          color="primary"
          component="h1"
        >
          {minutes}{new I18nService().getTranslationFor('minutesAbbreviation')} {seconds}{new I18nService().getTranslationFor('secondsAbbreviation')}
        </Typography>
      );
    }
  };

  return (
    <Fragment>
      <Box className={classes.content}>
        <ImageCarousel images={puzzle.images} />
        <Typography variant="h5" color="primary" component="h2">
          {puzzle.title}
        </Typography>
        <Typography variant="body1" color="textSecondary" component="p">
          {puzzle.description}
        </Typography>
        <Countdown date={counterDate} renderer={renderer} />
      </Box>
      <Box className={classes.footer}>
        <Button
          size="large"
          variant="contained"
          className={classes.button}
          disabled={!counterFinished}
          onClick={() => onComplete()}
        >
          {new I18nService().getTranslationFor('continueButton')}
        </Button>
      </Box>
    </Fragment>
  );
};

export default CounterPuzzle;
