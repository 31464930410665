import React, { useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  Box,
} from "@material-ui/core";
import { I18nService } from "../../services/i18n-service";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      height: "100vh",
      padding: 10
    },
    title: {
      alignSelf: "center"
    },
    label: {
      display: "flex"
    },
    textField: {},
    sendButton: {
      display: "flex"
    },
    alertComponent: {}
  })
);

type LanguageSwitcherProp = {
  onLanguageChange: Function
};

const LanguageSwitcher: React.FC<LanguageSwitcherProp> = ({onLanguageChange}) => {
  const classes = useStyles({});

  const [language, changeLanguage] = useState(new I18nService().getLanguage());

  const onSwitchLanguage = (language: string) => {
    new I18nService().setLanguage(language);
    changeLanguage(language);
    onLanguageChange();
  };

  const languages = new I18nService().getPossibleLanguages();
  const currentLanguage = new I18nService().getLanguage();
  const currentLanguageIndex = languages.findIndex((language) => {
    return language.language === currentLanguage;
  });
  const nextLanguageIndex = currentLanguageIndex < languages.length - 1 ? currentLanguageIndex + 1 : 0;
  const nextLanguage = languages[nextLanguageIndex];

  return (
    <Box className={classes.root}>
      <div className="language-switcher" onClick={() => {
        onSwitchLanguage(nextLanguage.language);
      }}>
        <img src={nextLanguage.image} alt="" />
      </div>
    </Box>
  );
};

export default LanguageSwitcher;
