import { Tour, PuzzleType } from "./types";

export const harryPotterTour: Tour = {
  id: 6,
  name: "Varázslat Budapesten",
  description: "",
  puzzles: [
    {
      id: 0,
      type: PuzzleType.START
    },
    {
      id: 1,
      type: PuzzleType.STANDARD,
      shortTitle: '1. etap',
      title: '1. etap',
      description: `Edics és Mikcsi a gellérthegyi házimanók, pontosabban a magyar mágiaügyi minisztérium házimanói, akiknek az a dolga, hogy a gellérthegyi kilátót rendben tartsák. Főleg azokban az időkben kell tisztaságnak lennie amikor az éves magyar varázsló gyülekezet van. Az idők során egymásba szerettek és négy év után úgy érezték itt az ideje, hogy összeházasodjanak. Ilyesmire eddig nem nagyon volt precedens és kérvényezték egybekelésüket. A Magyar mágiaügyi minisztériumtól sajnos nem kapták meg az engedélyt. Számukra az ilyesmi tűrhetetlen, így titokban készültek rá. Megkérték az egyik legrégebbi magyar varázsló sarját Molnár Sára boszorkányt, hogy adja őket össze.

      Edics sokszor beszélt arról Mikcsinek, hogy mennyire szereti és tiszteli az embereket, így Mikcsi egy nászajándékkal készült. Már előre készített egy százfülé főzetet, hogy mire az oltárhoz érnek, mindketten már emberi alakban jelenjenek meg.  A hozzávalókat Mikcsi  Sára boszorkánytól szerezte be, aki először ellenezte, majd beadta a derekát. Főleg, hogy ilyet még senki nem csinált és nem lehet tudni, hogy milyen hatással lesz a manókra. Az esküvőt a Gellérthegyi sziklatemplomhoz szervezték. Az esküvőre kizárólag titeket hívtak meg, mivel tudják, hogy szeretitek és kedvelitek a házimanókat.
      
      Budapest, Szent Gellért rkp. 1, 1114
      
      Írjatok be hogy: IGEN ha megérkeztetek`,
      images: [
        {
          url: require("../images/harryPotter/image-1.png"),
        }
      ],
      solutionRegExp: /^(Igen|igen|IGEN)$/,
      solutionKey: 'IGEN',
      hints: [
        {
          id: 0,
          text: 'Megoldás: Igen'
        }
      ]
    },
    {
      id: 2,
      type: PuzzleType.STANDARD,
      shortTitle: '2. etap',
      title: '2. etap',
      description: `Edics a bejáratnál átadott egy papírlapot Mikcsinek, hogy amikor vége az esküvőnek találkozzanak egy titkos helyen, amit előre feldíszített. Nagyon izgatott volt Edics, ezért Mikcsi kihasználva az alkalmat adott neki a százfülé főzetből, azt mondván, hogy ez nyugtató tea.  Amikor már majdnem odaértek az oltárhoz hírtelen mindketten rosszul lettek. Pár pillanat múlva emberi testük lett, bár mindketten ugyan olyan magasak maradtak, viszont a manó fejük nem változott. Edics sírva kirohant a templomból, Mikcsi próbálta magát összeszedni, amikor Sára boszorkány egy hirtelen rántással talpra állította és azt mondta: Azonnal menj utána mert egy hatalmas baziliszkusz ólálkodik a környéken, csak nem akartam mondani nehogy tönkre tegyem az esküvőtöket. Próbálj meg gondolkozni, hova mehetett? Adott egy papírlapot igaz? Mi áll rajta?  Segítsetek Mikcsinek megfejteni a feladványokat, mint egyedüli násznép, csak rátok számíthat. Senki mást nem ismer.

      Segíteni fogtok Mikcsinek? IGEN/NEM

      Megoldás: IGEN`,
      images: [
        {
          url: require("../images/harryPotter/image-2.png"),
        }
      ],
      solutionRegExp: /^(Igen|igen|IGEN)$/,
      solutionKey: 'IGEN',
      hints: [
        {
          id: 0,
          text: 'Megoldás: Igen'
        }
      ]
    },
    {
      id: 3,
      type: PuzzleType.STANDARD,
      shortTitle: '3. etap',
      title: '3. etap',
      description: `Papír Lap: 

      Ahogy te vagy szívem első szerelme, úgy az első varázspálca helyét kell meglelned, hogy utánam jöhess. Az első magyar varázspálcát belehelyezték egy szobor talapzatba. Segítségedre lehet egy fickó, aki egy templommal a kezében sétálgat. Milyen fordulón helyezhették el a varázspálcát?
      
      A Latin szó magyar megfelelőjét keressük!`,
      images: [
        {
          url: require("../images/harryPotter/image-3.jpg"),
        }
      ],
      solutionRegExp: /^(Ezred|EZRED|ezred)$/,
      solutionKey: 'Ezred',
      hints: [
        {
          id: 0,
          text: 'Keressétek a Szent Istvánt templommal a kezében.'
        },
        {
          id: 1,
          text: 'Egy latin szót keresünk.'
        },
        {
          id: 2,
          text: 'Megoldás: Ezred'
        }
      ]
    },
    {
      id: 4,
      type: PuzzleType.STANDARD,
      shortTitle: '4. etap',
      title: '4. etap',
      description: `Egy nagyon fontos szót fogunk most keresni, hogy megtudd hol lehetek. A következő szöveg hiányos. A hiányzó betűkből egy értelmes szónak kell kijönnie, minden betűt, csak egyszer használhatsz fel, hiába hiányzik belőle több. Tehát ha sok i betű hiányzik akkor is csak egyet használhatsz fel a megoldáshoz. Minden hiányzó i betű hosszú í-nek fog számítani. Azt keressük, ami már a tiéd.

      TO
      A KLATPLOBÓL, AIT A KOUNISTA HATALO BFALAZOTT.
      A 2-S BTONFAL VÉGLG LOLOTT
      1992. FEBRUÁR 26-ÁN

      NTO 
      A KLATPLOBÓL, AIT A KOUNISTA HATALO BFALAZOTT. 
      A 2-S BTONFAL  ÉGLG LOLOTT 
      1992. FEBRUÁR 26-ÁN
      `,
      images: [
        {
          url: require("../images/harryPotter/image-4.jpg"),
        }
      ],
      solutionRegExp: /^(Sz(i|í)vem|SZ(I|Í)VEM|sz(i|í)vem)$/,
      solutionKey: 'SZÍVEM',
      hints: [
        {
          id: 0,
          text: 'Keressétek a MEMENTÓ követ.'
        },
        {
          id: 1,
          text: '5 betűs szót keresünk, amely egy létfontosságú szerv.'
        },
        {
          id: 2,
          text: 'Megoldás: Szívem'
        }
      ]
    },
    {
      id: 5,
      type: PuzzleType.STANDARD,
      shortTitle: '5. etap',
      title: '5. etap',
      description: 'A papíron lévő szöveg folytatódik: Keresd meg a földön azt ami már a tiéd, állj rá és olvasd le a hatalmas épület nevét. Ott foglak várni téged a csarnokában.',
      images: [
        {
          url: require("../images/harryPotter/image-5.png"),
        }
      ],
      solutionRegExp: /^(Hotel-gellért-gyógyfűrdő|HOTEL-GELLÉRT-GYÓGYFŰRDŐ|hotel-gellért-gyógyfűrdő|Hotel-gellért-gyógyfürdő|HOTEL-GELLÉRT-GYÓGYFÜRDŐ|hotel-gellért-gyógyfürdő)$/,
      solutionKey: 'HOTEL-GELLÉRT-GYÓGYFŰRDŐ',
      hints: [
        {
          id: 0,
          text: 'Keressetek egy szívet a földön és olvassátok le az épület nevét.'
        },
        {
          id: 1,
          text: 'Három szóból áll.'
        },
        {
          id: 2,
          text: 'Megoldás: HOTEL-GELLÉRT-GYÓGYFÜRDŐ'
        }
      ]
    },
    {
      id: 6,
      type: PuzzleType.STANDARD,
      shortTitle: '6. etap',
      title: '6. etap',
      description: `Mikcsi most hallott két varázslót beszélni, hogy elszabadult egy hatalmas baziliszkusz a Hotel Gellért Gyógyfürdő csarnokában. Biztos, hogy tovább mentek, vagy jobbnak látjátok hogy haza menjetek? Tovább indultok?
      
      Igen/Nem`,
      images: [
        {
          url: require("../images/harryPotter/image-6.png"),
        }
      ],
      solutionRegExp: /^(Igen|IGEN|igen)$/,
      solutionKey: 'IGEN',
      hints: [
        {
          id: 0,
          text: 'Megoldás: Igen.'
        }
      ]
    },
    {
      id: 7,
      type: PuzzleType.STANDARD,
      shortTitle: '7. etap',
      title: '7. etap',
      description: `Menjetek a Gellért hegyi forrásház-hoz, állítólag ott szoktak furcsa sziszegő hangokat hallani az arra járó muglik. Budapest, Szent Gellért tér 1, 1111

      Megérkeztetek? Igen/Nem`,
      images: [
        {
          url: require("../images/harryPotter/image-7.jpg"),
        }
      ],
      solutionRegExp: /^(Igen|IGEN|igen)$/,
      solutionKey: 'IGEN',
      hints: [
        {
          id: 0,
          text: 'Megoldás: Igen.'
        }
      ]
    },
    {
      id: 8,
      type: PuzzleType.STANDARD,
      shortTitle: '8. etap',
      title: '8. etap',
      description: `Mikcsi: Sokszor jártunk ide Ediccsel, vagyis nem ide fel, hanem lent a csatornákat tisztítgatni nagyon romantikus volt. Egyszer egy gyönyörű csendes este, itt fent vallottam neki szerelmet. Emlékszem, akkor is ilyen sziszegő hangok voltak. Edics szeretett fotózni, és mivel lent a csatornák mind egy - egy oszlop felé vezettek, lefotózta őket. Adott mindegyiknek egy betűt, hogy tudjuk merre kell mennünk a tisztítás közben és ne tévedjünk el. Itt is vannak nálam a fotók. Mikcsi elkezdett turkálni a zsebében és egy fotó köteget húzott elő. Volt egy, amit olyan betűvel jelölt meg, ami itt a kupola szövegében nincs. Erre emlékszem, mert olyan furcsa szövetek voltak benne, mintha vedlene egy.... A felismeréstől elborzadt és egy halk pukkanás kíséretében eltűnt. Keressétek meg azt a képet amelyikhez a kakukktojás tartozhat és írjátok be megoldásnak

      A képek alapján találjátok meg a kakukktojást: NY, GY, SZ, TT, PŐ, TA, LY, KÁ
      
      Oldalra tudtok lapozni!`,
      images: [
        {
          url: require("../images/harryPotter/image-8-1.jpg"),
        },
        {
          url: require("../images/harryPotter/image-8-2.jpg"),
        },
        {
          url: require("../images/harryPotter/image-8-3.jpg"),
        },
        {
          url: require("../images/harryPotter/image-8-4.jpg"),
        },
        {
          url: require("../images/harryPotter/image-8-5.jpg"),
        },
        {
          url: require("../images/harryPotter/image-8-6.jpg"),
        },
        {
          url: require("../images/harryPotter/image-8-7.jpg"),
        },
        {
          url: require("../images/harryPotter/image-8-8.jpg"),
        }
      ],
      solutionRegExp: /^(Ká|KÁ|ká)$/,
      solutionKey: 'KÁ',
      hints: [
        {
          id: 0,
          text: 'A forrásház kupoláján belül van egy szöveg.'
        },
        {
          id: 1,
          text: 'Találjátok ki, melyik két betű nincs így egymás után következve.'
        },
        {
          id: 2,
          text: 'Megoldás: KÁ'
        }
      ]
    },
    {
      id: 9,
      type: PuzzleType.STANDARD,
      shortTitle: '9. etap',
      title: '9. etap',
      description: `Egy halk záródó hangot hallottatok, bezárult a csövek ajtaja, így a Baziliszkusz már visszafelé nem jöhet, csak egy irányba. A Hadik kávézó felé. A távolból hatalmas robaj hallatszódott, a Baziliszkusz megtámadta mérgében a kávézót. Haza mentek inkább, mert ennyi izgalom elég volt, vagy tovább mentek?

      IGEN/NEM`,
      images: [
        {
          url: require("../images/harryPotter/image-9.png"),
        }
      ],
      solutionRegExp: /^(Igen|IGEN|igen)$/,
      solutionKey: 'IGEN',
      hints: [
        {
          id: 0,
          text: 'Megoldás: Igen.'
        }
      ]
    },
    {
      id: 10,
      type: PuzzleType.STANDARD,
      shortTitle: '10. etap',
      title: '10. etap',
      description: `Bagoly postátok érkezett Sári boszorkánytól:  Mikcsit kővé dermedve találták, nem messze hadik kávézótól egy nagy szobor mellett. Egy szövegre mutogatott és egy papírdarabot szorongatott, amin ez állt: 

      Minden betű egynek számít, szóköz, pont nem számít. Ezt rejtegetik a kávézóban, és emiatt újra támadni fog a Baziliszkusz. Mi a megfejtés? 

      3/10, 1/2, 1/14,3/10, 7/25, 7/3, 7/4, 9/8

      Menjetek a Budapest, Bartók Béla út 36, 1111  közelébe és keressétek meg a libanoni cédrus táblát és fejtsétek meg a feladványt.`,
      images: [
        {
          url: require("../images/harryPotter/image-10.jpg"),
        }
      ],
      solutionRegExp: /^(Kiskígyó|KISKÍGYÓ|kiskígyó)$/,
      solutionKey: 'KISKÍGYÓ',
      hints: [
        {
          id: 0,
          text: 'Az első szám a sor.'
        },
        {
          id: 1,
          text: 'A második szám a betűt jelöli.'
        },
        {
          id: 2,
          text: 'Megoldás: KISKÍGYÓ'
        }
      ]
    },
    {
      id: 11,
      type: PuzzleType.STANDARD,
      shortTitle: '11. etap',
      title: '11. etap',
      description: `Használjátok a megfejtett kódot a Hadikban. “ A Kiskígyót keressük. Hol van?” 

      Ugyanis az történt, hogy az egyik felszolgáló miközben a baziliszkusz tört és zúzott, valahogy elkapta a kicsinyét, bezárta egy ládába és beejtette a varázspálcáját. Így a kígyó okozta kárt is másnak kellett megcsinálnia a reparó varázsigével.  Szerezzétek meg a varázspálcát, de még véletlenül se érjetek a ládában lévő kígyóhoz, ha valaki hozzáér, valaki más próbálkozzon, mert a kicsiknek a bőre is mérgező nem csak a tekintete. A Pálcát dugjátok el és mondjátok azt, hogy nem sikerült megszerezni. Mindig van egy tartalék pálcája Szelindi  a leghíresebb pálca készítő Budapesten és már jól ismeri, mindig elhagyja a pálcáját 1-2 pálcával kevesebb nem számít neki. Sikerült megszerezni a pálcát? 

      IGEN/NEM

      Ne próbáljátok kinyitni a ládát! A lukon keresztül dugjátok be a kezeteket és próbáljátok kihúzni a pálcát.`,
      images: [
        {
          url: require("../images/harryPotter/image-11.png"),
        }
      ],
      solutionRegExp: /^(Igen|IGEN|igen)$/,
      solutionKey: 'IGEN',
      hints: [
        {
          id: 0,
          text: 'Megoldás: Igen.'
        }
      ]
    },
    {
      id: 12,
      type: PuzzleType.COUNTER,
      shortTitle: "Szünet 1",
      title: "Szünet 1",
      description: `Igyatok addig valamit, aztán mehet a nyomozás.
      
      Dugjátok el a pálcát!`,
      images: [
        {
          url: require("../images/harryPotter/image-pause-1.png"),
        }
      ],
      amount: 600
    },
    {
      id: 13,
      type: PuzzleType.STANDARD,
      shortTitle: '12. etap',
      title: '12. etap',
      description: `Baglyotok érkezett Sára boszorkánytól: 
      “Hogy kik védelmezték Szent Imre Herceget. Varázslók? Rengetegen kővé dermedtek a városban rajtuk még tudtok segíteni, csak egy hely van ahol az ellenszer alapját, tehát Mandragóra kivonatot tudtok kapni. Viszont csak akkor viselkednek veletek úgy, mint a varázsvilág bennfenteseivel, ha megtudjátok mondani, hogy kik védelmezték Szent Imre Herceget Varázslók? Netán boszorkányok?  Keressétek meg Szent Imre Herceg szobrát és mondjátok meg kinek a kezében van főnix toll, kinek a kezében a Mágia története kötet és kinek van a kezében egy varázspálca botnak álcázva. Írjátok be a varázs számát. 

      3245 Egy boszorkány és három varázsló

      5555 Két boszorkány és egy varázsló

      4387 Három boszorkány és nincs varázsló

      1313 Három varázsló és nincs boszorkány
      
      Menjetek a Móricz Zsigmond körtèrre, ott találjátok a szobor csoportot.`,
      images: [
        {
          url: require("../images/harryPotter/image-12.jpg"),
        }
      ],
      solutionRegExp: /^(1313)$/,
      solutionKey: '1313',
      hints: [
        {
          id: 0,
          text: 'Keressétek meg a Szent Imre szobrot a Móricz Zsigmond körtéren és jól nézzétek meg a szobrokat kinek mi van a kezében.'
        },
        {
          id: 1,
          text: 'Egy tollat, egy könyvet és egy botot kell keresnetek.'
        },
        {
          id: 2,
          text: 'Megoldás: 1313'
        }
      ]
    },
    {
      id: 14,
      type: PuzzleType.STANDARD,
      shortTitle: '13. etap',
      title: '13. etap',
      description: `Menjetek a Rizmajer Sörház Móricz nevű helyre Budapest, Móricz Zsigmond körtér 4, 1114

      Most már tudjátok a jelszót: Három Varázsló a hely varázsvilági neve. Mondjátok a pultosnak, hogy a három varázsló nevű helyet keresitek és hogy szükségetek van a mandragóra kivonatra, mert egyre többen kővé dermednek a városban, közte a manótok is és valószínűleg a jövendőbeliét elrabolta egy Baziliszkusz. Ha nem akarja odaadni, fenyegessétek meg a varázspálcával, hogy használni fogjátok rajta a Cruciatus átkot. A varázspálcára nagyon vigyázzatok, mert az utolsóhelyen kelleni fog. Sikerült megszereznetek a kivonatot?
      
      IGEN/NEM`,
      images: [
        {
          url: require("../images/harryPotter/image-13.png"),
        }
      ],
      solutionRegExp: /^(Igen|IGEN|igen)$/,
      solutionKey: 'IGEN',
      hints: [
        {
          id: 0,
          text: 'Megoldás: Igen.'
        }
      ]
    },
    {
      id: 15,
      type: PuzzleType.COUNTER,
      shortTitle: "Szünet 2",
      title: "Szünet 2",
      description: "Igyatok addig valamit, aztán mehet a nyomozás.",
      images: [
        {
          url: require("../images/harryPotter/image-pause-2.png"),
        }
      ],
      amount: 600
    },
    {
      id: 16,
      type: PuzzleType.STANDARD,
      shortTitle: '14. etap',
      title: '14. etap',
      description: `A feneketlen tónál látták utoljára a Baziliszkuszt. Ne próbáljátok meg megölni. SŐT! Kerüljétek a tó felszínének nézegetését, nehogy véletlenül a szemébe nézzetek akár közvetlenül, akár vízen keresztül. Az ellenszerhez még meg kell szereznetek egy falevelet, de előtte ébresszetek fel egy szobrot, hogy felvegye a harcot a Baziliszkusszal és megmentse Edicst. Keressétek Brenner János szobrát és jöjjetek rá, hogy mi válik a javukra az isten szeretőknek. Írjátok be ide megfejtésnek.`,
      images: [
        {
          url: require("../images/harryPotter/image-14.png"),
        }
      ],
      solutionRegExp: /^(minden|Minden|MINDEN)$/,
      solutionKey: 'MINDEN',
      hints: [
        {
          id: 0,
          text: 'Brenner János egy teljes alakos szobra a feneketlen tónál található.'
        },
        {
          id: 1,
          text: 'A tó templomhoz közeli részénél található.'
        },
        {
          id: 2,
          text: 'Megoldás: Minden'
        }
      ]
    },
    {
      id: 17,
      type: PuzzleType.STANDARD,
      shortTitle: '15. etap',
      title: '15. etap',
      description: `Ügyesek vagytok! A szobor hamarosan Edics megmentésére indul. Ti addig menjetek és keressétek meg a halál kapuját, amely eddig a rejtélyügyi főosztályon volt. Amennyiben hátulról átnéztek rajta, meglátjátok a dombon túl azt a fúriafűzt, amit kereszteztek egy másik fával, és amelynek levele kelleni fog a méregkeverőnek, hogy elkészítse az ellenszert az embereknek. Sikerült megszerezni a levelet? Ha nincs falevél egy pár milliméteres fadarab is megteszi. A tónak a templomhoz közelebbi oldalán találjátok a halál kapuját.

      5 törzse van a fának!
      
      Megvan? IGEN/NEM`,
      images: [
        {
          url: require("../images/harryPotter/image-15.jpg"),
        }
      ],
      solutionRegExp: /^(Igen|IGEN|igen)$/,
      solutionKey: 'IGEN',
      hints: [
        {
          id: 0,
          text: 'Megoldás: Igen.'
        }
      ]
    },
    {
      id: 18,
      type: PuzzleType.STANDARD,
      shortTitle: '16. etap',
      title: '16. etap',
      description: `Hamarosan végeztek, már csak a két manó lakatját kell megtalálnotok, amelyen rajta van a varázsszó, amelyet egymásnak és mindenkinek elmondanak, hogy egy picit varázslatosabbá tegyék a világot és amivel az utolsó helyen is igazolni tudjátok, hogy a varázslóvilághoz tartoztok. Keressétek a tó körül a stéget. Megtaláltátok a lakatot? Mi a varázsszó?`,
      images: [
        {
          url: require("../images/harryPotter/image-16.jpg"),
        }
      ],
      solutionRegExp: /^(Szeretlek|SZERETLEK|szeretlek)$/,
      solutionKey: 'SZERETLEK',
      hints: [
        {
          id: 0,
          text: 'Keressetek egy lakatot amin rajta van Edics és Mikcsi neve.'
        },
        {
          id: 1,
          text: 'Egy szerelem lakatról van szó.'
        },
        {
          id: 2,
          text: 'Megoldás: SZERETLEK'
        }
      ]
    },
    {
      id: 19,
      type: PuzzleType.STANDARD,
      shortTitle: '17. etap',
      title: '17. etap',
      description: `A két házimanót megtalálták és a kedvenc helyükre vitték őket, közel a feneketlen tóhoz. Bár a helyen megállt az idő, siessetek, hogy ne maradjatok le a találkozásukról. Ne csodálkozzatok, ha a hely réginek tűnik és picit lassúnak, évekkel ezelőtt elbűvölték a helyet. A Hely neve: Semmi Extra Bartók.  Budapest, Bartók Béla út 62-64, 1114 Amint megérkeztek, adjátok oda a hozzávalókat a felszolgálónak, hogy elkészíthesse az ellenszert, az elkészítésért cserébe elfogja kérni a varázspálcát, adjátok oda neki. Nektek is készíteni fog valamit mivel van köztetek olyan, aki hozzáért a kiskígyóhoz, vagy látta a tóban a villogó szemeket és hamarosan átfog változni, ha nem iszik az ellenszerből, csak nem meri bevallani. Szóval igyatok vele. Amikor átadjátok a hozzávalókat a pincérnek, mondjátok a varázsszót!
      
      SZERETLEK!
      
      Mondtátok a varázsszót? IGEN/NEM`,
      images: [
        {
          url: require("../images/harryPotter/image-17.png"),
        }
      ],
      solutionRegExp: /^(Igen|IGEN|igen)$/,
      solutionKey: 'IGEN',
      hints: [
        {
          id: 0,
          text: 'Megoldás: Igen.'
        }
      ]
    },
    {
      id: 20,
      type: PuzzleType.END,
      shortTitle: '18. etap',
      title: '18. etap',
      description: `A Baziliszkuszt befogták az Aurorok és nincs már mitől félni. Mikcsi és Edics megkapta az ellenszert és megtartották a lakodalmat. Megbeszélték egymás között a félreértést, hogy Mikcsi azért adott százfülé főzetett, mert azt hitte, hogy Edics emberré szeretne válni, miközben Ő imádja a házimanó létet, csak azért beszél sokat az emberekről mert tiszteli és szereti őket és azért futott el, mert nem értette mi történik vele és szégyellte magát. Béke és harmónia lett köztük és köszönik a sok segítséget, amit adtatok nekik, hogy újra egymáséi lehessenek. Felépítettek egy házikót a feneketlen tó mellett és most már onnan járnak be dolgozni a Gellérthegyre.
      
      “Gratulálunk nektek és köszönjük! Ne felejtsétek elmondani a kedvenc varázs szavunkat, hogy Szeretlek, mert ha egyszer ti is kővé dermedtek, sok ideig nem mondhatjátok” Gratulálunk! Ha szeretnétek látni minket, itt vagyunk egy akváriumban. Keressetek meg minket. Puszil titeket a két házimanó!`,
      images: [
        {
          url: require("../images/harryPotter/image-18.png"),
        }
      ]
    }
  ]
};
