import { Tour, PuzzleType } from "./types";

export const ballOfPuskasTour: Tour = {
  id: 5,
  name: "Puskás öcsi labdája",
  description: "",
  puzzles: [
    {
      id: 0,
      type: PuzzleType.START
    },
    {
      id: 1,
      type: PuzzleType.STANDARD,
      shortTitle: '1. etap',
      title: '1. etap',
      description: `Mint tudjátok a Trollfoci tulajdonosának ellopták a dedikált Puskás Öcsi labdáját. Már nekiindult egyszer,hogy a nyomokat követve megtalálja és visszakövetelje a labdát az elrablótól. Sajnos ez idáig még nem járt sikerrel a barátait nem akarja cserbenhagyni, hogy egyedül igyanak és közben nélküle drukkoljanak a csapatuknak, hiszen megint meccs van. Rajtatok múlik, hogy sikerül-e lefülelni a notórius tolvajt, vagy örökre benyeli a város az aláírt nemzeti kincset. Ti most ne a meccset nézzétek, hanem a pulthoz közel van egy szekrény, abban vannak azok a holmik, amik a tolvaj táskájában voltak. A táskáját ugyanis elhagyta menekülés közben. Mindent jegyezzetek fel, mert mindenre szükségetek lehet a felderítés során.
      
      Végeztetek a szekrénnyel és mindent feljegyeztetek?
      
      Írjátok be hogy igen.`,
      images: [
        {
          url: require("../../assets/images/ballOfPuskas/etap_1.jpg"),
        }
      ],
      solutionRegExp: /^(Igen|igen|IGEN)$/,
      solutionKey: 'IGEN',
      hints: [
        {
          id: 0,
          text: 'Igen'
        }
      ]
    },
    {
      id: 2,
      type: PuzzleType.COUNTER,
      shortTitle: "Szünet",
      title: "Szünet",
      description: "Igyatok addig valamit, aztán mehet a nyomozás.",
      images: [
        require("../../assets/images/ballOfPuskas/pause.jpg")
      ],
      amount: 600
    },
    {
      id: 3,
      type: PuzzleType.STANDARD,
      shortTitle: '2. etap',
      title: '2. etap',
      description: `Keressétek meg azt, ami jelzi, hol volt a király páholya és használjátok fel Lehel nyomozótól kapott nyomokat: L, 4/6, M, 4/6, 2/12, 1/6, U, 4/12, 2/2, Ó
      
      A szekrényben található címre menjetek.`,
      images: [
        {
          url: require("../../assets/images/ballOfPuskas/etap_2.jpg"),
        }
      ],
      solutionRegExp: /^(Lemezkuckó|lemezkuckó|LEMEZKUCKÓ)$/,
      solutionKey: 'LEMEZKUCKÓ',
      hints: [
        {
          id: 0,
          text: 'A számolásnál az első szám a sort jelöli a második szám, hogy hányadik betű a sorban.'
        },
        {
          id: 1,
          text: 'Minden betú egyet jelent, szóköz nem számít.'
        },
        {
          id: 2,
          text: 'Lemezkuckó'
        }
      ]
    },
    {
      id: 4,
      type: PuzzleType.STANDARD,
      shortTitle: '3. etap',
      title: '3. etap',
      description: `Nézzétek meg a videót!! Menjetek fel youtubera és nézzétek meg amikor Levente kereste a labdát mit sikerült kiderítenem. Aztán írjátok be ide a megfejtés hogy megtudjátok hova kell mennetek. Videó név: „Levente a lemezkuckónál”

      https://www.youtube.com/watch?v=dZxQFWlnGS8
      
      Hova kell mennetek a következő nyomért?
      
      Ne menjetek be a lemezkuckóba!`,
      images: [
        {
          url: require("../../assets/images/ballOfPuskas/etap_3.jpg"),
        }
      ],
      solutionRegExp: /^(Erzsébet Körút 53|Erzsébet Körút 53.|erzsébet körút 53|erzsébet körút 53.|ERZSÉBET KÖRÚT 53|ERZSÉBET KÖRÚT 53.|erzsébetkörút53|erzsébetkörút53.|ERZSÉBETKÖRÚT53|ERZSÉBETKÖRÚT53.)$/,
      solutionKey: 'ERZSÉBET KÖRÚT 53',
      hints: [
        {
          id: 0,
          text: 'Írd be először a körút nevét, aztán a körút szót végül a számot hozzá'
        },
        {
          id: 1,
          text: 'Mindent egybe írjál szóköz nélkül.'
        },
        {
          id: 2,
          text: 'Erzsébet körút 53.'
        }
      ]
    },
    {
      id: 5,
      type: PuzzleType.STANDARD,
      shortTitle: '4. etap',
      title: '4. etap',
      description: `Az Erzsébet Körút 53 szám alatt van két kirakat. Az egyikben van egy szöveg, fejtsétek meg és írjátok be a következő helyszín címét.
      
      Honnan lopták el?`,
      images: [
        {
          url: require("../../assets/images/ballOfPuskas/etap_4.jpg"),
        }
      ],
      solutionRegExp: /^(Zeneakadémia|zeneakadémia|ZENEAKADÉMIA|Zeneakademia|zeneakademia|ZENEAKADEMIA)$/,
      solutionKey: 'ZENEAKADÉMIA',
      hints: [
        {
          id: 0,
          text: 'Használjatok fejben egy tükröt!'
        },
        {
          id: 1,
          text: 'Olvassátok visszafelé!'
        },
        {
          id: 2,
          text: 'Zeneakadémia'
        }
      ]
    },
    {
      id: 6,
      type: PuzzleType.STANDARD,
      shortTitle: '5. etap',
      title: '5. etap',
      description: 'Menjetek a Zeneakadémiához és keressétek meg a Lemezkuckós fia biciklijének a helyét. A tolvaj kérkedésből lehet, hogy hagyott valami nyomot. Érdemes az épület környékén körbenézni. Segítségetekre lesz a kép, amit a vitrinben láttatok. Láttok valami írást rajta?',
      images: [
        {
          url: require("../../assets/images/ballOfPuskas/etap_5.jpg"),
        }
      ],
      solutionRegExp: /^(Ruin brew|ruin brew|RUIN BREW)$/,
      solutionKey: 'RUIN BREW',
      hints: [
        {
          id: 0,
          text: 'Keressétek a kép alapján, hol lehetett a bicikli.'
        },
        {
          id: 1,
          text: 'Az épület környékén keressetek egy lakatot.'
        },
        {
          id: 2,
          text: 'RUIN BREW'
        }
      ]
    },
    {
      id: 7,
      type: PuzzleType.STANDARD,
      shortTitle: '6. etap',
      title: '6. etap',
      description: 'Menjetek a RUIN BREW bárba. Budapest, Akácfa u. 54-56, 1073. Mondjátok a pultosnak a vitrinben látott jelszót és írjátok be ide megoldásnak ha beszéltetek a pultossal!',
      images: [
        {
          url: require("../../assets/images/ballOfPuskas/etap_6.png"),
        }
      ],
      solutionRegExp: /^(Csókot loptam|csókot loptam|CSÓKOT LOPTAM)$/,
      solutionKey: 'CSÓKOT LOPTAM',
      hints: [
        {
          id: 0,
          text: 'Volt a vitrinben egy jelszó. Arra emlékezzetek vissza!'
        },
        {
          id: 1,
          text: 'Valakinek az ajkáról lopott valamit.'
        },
        {
          id: 2,
          text: 'Csókot loptam'
        }
      ]
    },
    {
      id: 8,
      type: PuzzleType.COUNTER,
      shortTitle: "Szünet 2",
      title: "Szünet 2",
      description: "Igyatok addig valamit, aztán mehet a nyomozás.",
      images: [
        require("../../assets/images/ballOfPuskas/pause.jpg")
      ],
      amount: 600
    },
    {
      id: 9,
      type: PuzzleType.STANDARD,
      shortTitle: '7. etap',
      title: '7. etap',
      description: `Lehel ezredes kiderítette, hogy a tolvaj dealerkedik  is. Oldjátok meg a pultostól kapott feladatot és írjátok be ide megoldást.
      
      Írjátok be ide megoldásnak a címet amit megfejtettetek.`,
      images: [
        {
          url: require("../../assets/images/ballOfPuskas/etap_7.jpg"),
        }
      ],
      solutionRegExp: /^(Kertész utca 38|kertész utca 38|KERTÉSZ UTCA 38|Kertészutca38|kertészutca38|KERTÉSZUTCA38)$/,
      solutionKey: 'KERTÉSZ UTCA 38',
      hints: [
        {
          id: 0,
          text: 'Írjátok be az arany csapat neveit!'
        },
        {
          id: 1,
          text: 'Első szám hogy hányadik sor, a második szám a rublikát jelöli.'
        },
        {
          id: 2,
          text: 'Kertész utca 38'
        }
      ]
    },
    {
      id: 10,
      type: PuzzleType.STANDARD,
      shortTitle: '8. etap',
      title: '8. etap',
      description: `Menjetek be a Kertész utca 38 számba és fogadjatok a döntetlenre vagy keressétek azt ahova általában elássák a dolgokat. Nyissátok ki a kóddal és vegyetek ki egyet a tartalmából, többet ne, mert a játék véget ér!

      Megtaláltátok?
      
      Igen/Nem
      
      Úgy tudjátok visszazárni, hogy a kóddal vissza csukjátok 1990 és elforgatjátok tetszőleges számokhoz.

      Ha nem sikerül, hívjátok a központi számot és kérjetek segítséget a játékmestertől.
      
      Csomag megörzőben keressétek az X -et, érdemes a kulcsszéfek között körbenézni.`,
      images: [
        {
          url: require("../../assets/images/ballOfPuskas/etap_8.jpg"),
        }
      ],
      solutionRegExp: /^(Igen|igen|IGEN)$/,
      solutionKey: 'IGEN',
      hints: [
        {
          id: 0,
          text: 'Írjátok be hogy IGEN odaértünk.'
        },
        {
          id: 1,
          text: 'Keressétek az X-et ami a döntetlen jelzése a fogadásoknál!'
        },
        {
          id: 2,
          text: 'Igen'
        }
      ]
    },
    {
      id: 11,
      type: PuzzleType.STANDARD,
      shortTitle: '9. etap',
      title: '9. etap',
      description: `Lehel ezredes most kapta a fülest hogy a Score nevű helyen tudhatnak valamit a srácról. Menjetek és beszéljetek az egyik pultossal. Próbáljatok inkognitóban maradni. Mondjátok hogy ehhez hasonló cuccot vásárolnátok a sráctól. Mutassátok meg neki amit szereztetek.
      
      Beszéltetek a pultossal?
      
      Igen/Nem
      
      Ha nem sikerült visszazárni a kulcsszéfet inkább hívjátok a központi számot és szóljatok! 06304814830`,
      images: [
        {
          url: require("../../assets/images/ballOfPuskas/etap_9.jpg"),
        }
      ],
      solutionRegExp: /^(Igen|igen|IGEN)$/,
      solutionKey: 'IGEN',
      hints: [
        {
          id: 0,
          text: 'Írjátok be hogy IGEN!'
        }
      ]
    },
    {
      id: 12,
      type: PuzzleType.STANDARD,
      shortTitle: '10. etap',
      title: '10. etap',
      description: `Ahogy a pultos is elmondta, a falon van egy lottószelvény a tolvaj annyira nagyképű volt, hogy kirakta ide mindenki szeme láttára és mégsem tudja senki, hogy ez a Ziccer egyik széfjének kulcsa, amit napok óta nem tudnak kinyitni. Keressétek meg a képet, jegyezzétek meg a számokat és menjetek vissza a Ziccerbe kinyitni a széfet.

      Gúnyból, a tolvaj az ezredes születési dátumát állította be.
      
      Milyen színű a képkeret?`,
      images: [
        {
          url: require("../../assets/images/ballOfPuskas/etap_10.png"),
        }
      ],
      solutionRegExp: /^(Ezüst|ezüst|EZÜST)$/,
      solutionKey: 'EZÜST',
      hints: [
        {
          id: 0,
          text: 'Ha nem találjátok, kérjetek segítséget a pultostól!'
        },
        {
          id: 1,
          text: 'Ezüst'
        }
      ]
    },
    {
      id: 13,
      type: PuzzleType.COUNTER,
      shortTitle: "Szünet 3",
      title: "Szünet 3",
      description: "Igyatok addig valamit, aztán mehet a nyomozás.",
      images: [
        require("../../assets/images/ballOfPuskas/pause.jpg")
      ],
      amount: 600
    },
    {
      id: 14,
      type: PuzzleType.STANDARD,
      shortTitle: '11. etap',
      title: '11. etap',
      description: `A bejárati ajtó mellett találjátok a széfet, Levente már kifeküdt, ittasan. Menjetek és nyissátok ki a lottószámokkal a széfet.

      Keressétek meg a széfet és nyissátok ki a lottószámokkal, ami ügye Lehel ezredes születési dátuma, csak az egyjegyű számokat használjátok: 1, 8, 5, 9

      Milyen felirat van a széfben?`,
      images: [
        {
          url: require("../../assets/images/ballOfPuskas/etap_11.jpg"),
        }
      ],
      solutionRegExp: /^(Fedezőárnyék|fedezőárnyék|FEDEZŐÁRNYÉK)$/,
      solutionKey: 'FEDEZŐÁRNYÉK',
      hints: [
        {
          id: 0,
          text: 'A széfben fel kell írva lennie egy szónak.'
        },
        {
          id: 1,
          text: 'FEDEZŐÁRNYÉK'
        }
      ]
    },
    {
      id: 15,
      type: PuzzleType.END,
      shortTitle: '12. etap',
      title: '12. etap',
      description: `Gratulálunk! Megvan a labda, igaz kicsit összezsugorították bosszúból, de majd Leventéék felnagyítják. Zárjátok be a széfet és ha ez megvolt, akkor menjetek a pulthoz, mondjátok a széfben található jelszót, fedező árnyék és vendégeink vagytok egy Shotra.
      
      Köszönjük!`
    }
  ]
};
