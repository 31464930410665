import { Tour, PuzzleType } from "./types";

export const freemansoryTour: Tour = {
  id: 4,
  name: "Magyar szabadkőművesek a Fiumei úti sírkertben",
  description:
    "Rengeteg szabadkőműves élt és él ma is Magyarországon, akik tudás formájában kincseket felhalmoztak fel. Ebből fogtok most néhányat magatokhoz venni a kutatás során. Fedezzétek fel, hogy mennyi híres ember ismerkedett a tanokkal, akik egy karnyújtásnyira vannak tőletek.",
  puzzles: [
    {
      id: 0,
      type: PuzzleType.START,
    },
    {
      id: 1,
      type: PuzzleType.STANDARD,
      shortTitle: "Wekerle",
      title: `Wekerle Sándor`,
      description: `Rengeteg szabadkőműves élt és él ma is Magyarországon, akik tudás formájában kincseket felhalmoztak fel. Ebből fogtok most néhányat magatokhoz venni a kutatás során. Fedezzétek fel, hogy mennyi híres ember ismerkedett a tanokkal, akik egy karnyújtásnyira vannak tőletek.

      A legközelebbi szabadkőműves Wekerle Sándor, volt miniszterelnök.  

      KERESSÉTEK MEG A 10/1-1-5 PARCELLÁT ÉS FEJTSÉTEK MEG, HOGY A FELSOROLT NÖVÉNYEK KÖZÜL MELYIK TALÁLHATÓ A SÍRJA KÖRÜL?
      KORALLBERKENYE, BORBOLYA, BABÉRMEGGY, LONC, DÉRBABÉR
            `,
      images: [
        {
          url: require("../../assets/images/freemansory/1 szabadkőműves kép 1.jpg"),
        },
        {
          url: require("../../assets/images/freemansory/2 Wekerle Sándor 2 kép.jpg"),
        },
      ],
      solutionRegExp: /^(BABERMEGGY|Babermeggy|babermeggy|BABÉRMEGGY|Babérmeggy|babérmeggy)$/,
      solutionKey: "BABERMEGGY",
      hints: [
        {
          id: 0,
          text: "HASZNÁLJÁTOK AZ INTERNETET",
        },
        {
          id: 1,
          text:
            "A SKIMMIA LEVELE SÖTÉTEBB-OLAJZÖLD, MÍG A BABÉRMEGGYÉ ÉLÉNKEBB ÜDE-ZÖLD SZÍNŰ",
        },
        {
          id: 2,
          text: 'A megoldás "BABÉRMEGGY"',
        },
      ],
    },
    {
      id: 2,
      type: PuzzleType.STANDARD,
      shortTitle: "Horthy",
      title: `848`,
      description: `1872-ben, 24 éves korában vették fel a Magyarországi Nagyoriens Könyves Kálmán páholyába, ahol még abban az évben legénnyé, majd mesterré avanzsált. 
’72-ben először a templomőri, majd a szónoki tisztséget töltötte be, ezt követő ’74. márciusában, pedig kilépett páholyából és befogadta a Hungária páholy, ahol ’75-’76-ban szónokként működött. Skót rítusú szabadkőművesként ’82-’83-ban a 18-as káptalan tagja volt. ’86. December 3-án végül törölték tagságát, tagdíj fizetési elmaradás miatt.
            
A szabadkőműves kifejezés, a ’freemason’ angol szó magyarítása. A freestone mason ’puhakő-faragó’ szavak összetételével jött létre, ami a művészi díszítéseket, faragványokat, szobrokat készítő kőműveseket jelentette. Ennek ellentéte a roughstone mason ’keménykő-faragó’, az épületek építőelemeit készítő, kevésbé igényes munkát végző kőműves volt.

A faragás és díszítés tehát rendkívül fontos volt, ennek az egyik nagy művelőjének sírjához fogtok ellátogatni.
KERESSÉTEK HORTY PÁL SÍRJÁT A 17/3-1-24 PACELLÁBAN. 
A KÉRDÉS A KÖVETKEZŐ: HÁNY TULIPÁN TALÁLHATÓ HORTY PÁL SÍRJÁN?
`,
      images: [
        {
          url: require("../../assets/images/freemansory/3 Wekerle Sándor sírja kép 3_.jpg"),
        },
      ],
      solutionRegExp: /^(10|TIZ|TÍZ)$/,
      solutionKey: "10",
      hints: [
        {
          id: 0,
          text: "NÉZZÉTEK JÓL MEG A SÍRJÁT",
        },
        {
          id: 1,
          text: "JÁRJÁTOK KÖRBE A SÍRT",
        },
        {
          id: 2,
          text: 'A megoldás "10"',
        },
      ],
    },
    {
      id: 3,
      type: PuzzleType.STANDARD,
      shortTitle: "Temetés",
      title: `Temetés`,
      description: `1903-ban vették fel az Erzsébet szabadkőműves páholyba. Munkáival részt vett a párizsi (1900), a torinói (1902), a st. louisi (1904) világkiállításon. Számos megbízást kapott itt, s ezért szabadságát meghosszabbítva bútorokat, üvegfestményeket, mozaik díszítéseket tervezett s grafikai munkákkal foglalkozott, egyéb, új technikákkal kísérletezett. 1906-ban a magyar kormány támogatásával Mexikóba utazott, s az ottani prekolumbián kerámia emlékeiből gazdag anyagot gyűjtött. Négy hónapig tartózkodott itt, s sárgalázban megbetegedett. Innen Japánba utazott, s egy hónapot töltött főként Jokohamában és Tokióban. Hazafelé jövet Bombayban ismét sárgalázba esett és 6 napos agónia után meghalt.
            
      KI HOZATTA HAZA, HOGY ELTEMESSÉK?
      `,
      images: [
        {
          url: require("../../assets/images/freemansory/4 Horty Pál kép 4.jpg"),
        },
      ],
      solutionRegExp: /^(BANYAI ILONA|BÁNYAI ILONA)$/,
      solutionKey: "BANYAI ILONA",
      hints: [
        {
          id: 0,
          text: "NÉZZETEK UTÁNA AZ INTERNETEN",
        },
        {
          id: 1,
          text: "HOGY HÍVTÁK A SÍR ALAPJÁN AZT A SZEMÉLYT?",
        },
        {
          id: 2,
          text: 'A megoldás "BÁNYAI ILONA"',
        },
      ],
    },
    {
      id: 4,
      type: PuzzleType.STANDARD,
      shortTitle: "Ady",
      title: `Ady Endre`,
      description: `
Ady Endre már nagyváradi tartózkodása (1900–1903) alatt megismerhette a szabadkőműves eszméket, ott működött ugyanis a László Király Páholy.
Célkitűzéseiket a ’László Király Páholy könyvtára’ című folyóiratban tették közzé – ez feltehetőleg Adyhoz is eljutott, ugyanis a ’Szabadságban’ és a ’Nagyváradi Naplóban’ megjelent publicisztikájában felismerhetők azok az eszmék, amelyeket az említett kiadványok fogalmaztak korábban – Ismert róla, hogy az eszme közvetett terjesztését leszámítva, nem vett részt a páholy mindennapi tevékenységében.     

A képzőművészet és építészet nagyon közel áll egymáshoz, ahogy most két szabadkőművesünk végső nyughelye is. Ady Endrétől nem messze megtaláljátok a Vajda Hunyad vár tervezőjét. KI Ő?`,
      images: [
        {
          url: require("../../assets/images/freemansory/5 Ady Endre kép 5.jpg"),
        },
      ],
      solutionRegExp: /^(ALPÁR IGNÁCZ|ALPAR IGNACZ|ALPAR IGNAC)$/,
      solutionKey: "ALPÁR IGNÁCZ",
      hints: [
        {
          id: 0,
          text: "A SÍRJÁN TÖBB ÉPÜLET IS TALÁLHATÓ",
        },
        {
          id: 1,
          text:
            "HA ADYVAL SÍRJÁVAL SZEMBEN ÁLLTOK, JOBBRA KELL INDULNOTOK AZ ÁRKÁDSOR KÖZÖTT",
        },
        {
          id: 2,
          text: 'A megoldás "ALPÁR IGNÁCZ"',
        },
      ],
    },
    {
      id: 5,
      type: PuzzleType.STANDARD,
      shortTitle: "Palota",
      title: `Palota`,
      description: `Alpár Ignácot 30 éves korában vették fel a Corvin Mátyás páholyba. 1903-ban Szövetségtanácsi tag volt. Berényi szerint 1906-ban fedezett, Palatinus viszont törlését közli.
– Emlékezetére szabadkőműves testvére, Telcs Ede készített először kisplasztikát (Toronyépítő, 1924), majd 1831-ben emlékmű szobrot a Városligetben.

A SÍREMLÉKEN EGY „PALOTA” LÁTHATÓ. MELY PALOTA EZ?
            `,
      images: [
        {
          url: require("../../assets/images/freemansory/6 Alpár Ignácz kép 6.jpg"),
        },
        {
          url: require("../../assets/images/freemansory/7 Alpár Ignácz kép 7.jpg"),
        },
      ],
      solutionRegExp: /^(TŐZSDEPALOTA|TOZSDEPALOTA|TÖZSDEPALOTA)$/,
      solutionKey: "TŐZSDEPALOTA",
      hints: [
        {
          id: 0,
          text: "PÉNZÜGYI INTÉZMÉNYKÉNT, MAJD TELEVÍZIÓ-SZÉKHÁZKÉNT MŰKÖDÖTT",
        },
        {
          id: 1,
          text:
            "1054, BUDAPEST SZABADSÁG TÉR 17. CÍMEN MEGTALÁLJÁTOK AZ INTERNETEN",
        },
        {
          id: 2,
          text: 'A megoldás "TŐZSDEPALOTA"',
        },
      ],
    },
    {
      id: 6,
      type: PuzzleType.STANDARD,
      shortTitle: "Piramis",
      title: `Piramis`,
      description: `Az operatív kőművesség és építészet eszköztára, köztudottan a szabadkőművesség ősi, metaforikus szimbólumrendszerét alkotják. A szabadkőművesség ikonográfiájának, nyelvezetének három fő forrása: az építészet, az okkultizmus és a keresztény szimbolika. – az első és második fok eszközei a közvetlen cselekvésre, míg a harmadik foké a vezető és szellemi munkára, kiteljesedére utalnak.

      Az ősi tudás legendákban, allegóriákban, mítoszokban és jelképekben van kifejtve. A jelképek értéke tehát nem is annyira tartalmukban, jelentésükben rejlik, mintsem amit felhasználásuk, alkalmazásuk során – az emberi archetípusokra támaszkodva – a rituálék során kifejteni képesek, így megelevenítve a „régi idők, örök érvényű üzenetét”. Ilyen módon a szabadkőműves szimbólumok részei az európai szinkretikus hagyománynak, amely maga a pogány és keresztény filozófiai és hiedelemrendszerek összeolvadásából alakult ki és mint ilyen, máig egyedülálló.

      A valódi tudás tehát nem a jelképek egy „tárgyilagos értékében” keresendő, hanem ott rejtőzik, amikor az ember képes értelmezni és beépíteni jelentésüket a mindennapi életébe. A régi idők bölcsességét tehát azok ismerhetik meg, akik megfejtik ezen szimbólumok és allegóriák értelmét, jelentését.
            
      Ezt alátámasztandó, az egyik legismertebb, szabadkőműves szimbolika eszköztárában is közkedvelten alkalmazott – 2-dimenziós ábrázolásban szabályos, egyenlő oldalú háromszögként ábrázolt – „piramis”, ami a hierarchia legprecízebb leképezése. A „szent hegy”csúcsa, a legfelsőbb szellemi képesség kifejezője, mely az elsődleges teremtő igét, a minden földi dolgok irányítóját hivatott szimbolizálni.

      KERESSÉTEK A 20-1-40 PARCELLÁBAN AZT A SZABADKŐMŰVEST, AKI A SZABÁLYOS PIRAMIS ALAKZATOT HASZNÁLJA NYUGHELYÉN.
HOGY HÍVJÁK?
      `,
      images: [
        {
          url: require("../../assets/images/freemansory/8 Szimbólumok kép 8.jpg"),
        },
        {
          url: require("../../assets/images/freemansory/9 Dollár kép 9.jpg"),
        },
      ],
      solutionRegExp: /^(BÁNYAI ELEMÉR|BANYAI ELEMER)$/,
      solutionKey: "BÁNYAI ELEMÉR",
      hints: [
        {
          id: 0,
          text: "(1875-1915).",
        },
        {
          id: 1,
          text: "FELÚJÍTATTA A XVI KERÜLETI ÖRMÉNY ÖNKORMÁNYZAT",
        },
        {
          id: 2,
          text: 'A megoldás "BÁNYAI ELEMÉR"',
        },
      ],
    },
    {
      id: 7,
      type: PuzzleType.STANDARD,
      shortTitle: "Válaszoljatok a kérdésekre",
      title: `Válaszoljatok a kérdésekre`,
      description: `Bányai Elemér egyetemi tanulmányait és szerkesztői előéletét követően, 1904-ben kötözött Budapestre, ahol a Magyar Nemzeti Múzeum könyvtárában, majd 1910-től a ’Magyar Nemzet’ munkatársaként dolgozott.
A Martinovics szabadkőműves páholyba is etájt vették fel. Történelemfilozófiai pesszimizmusa ellenére a polgári radikalizmus jeles szószólója volt. 1914-től így Magyarország eszmei szférájának belső munkatársaként tekintik. A ’Vasárnapi Újságba’, a ’Pesti Naplóba’, a ’Magyar Nemzetbe’ írt cikkeket.
Az első világháborúban önkéntesként vonult be és orosz fronton esett el.
Ady Endre talán legigazibb barátja, neki címezte a Négy-öt magyar összehajol, halálhírére pedig a Szegény Zuboly emlékére és a Zuboly emléktáblájára című verseit.

„Zuboly, nézz bennünket!
Te szabad hajdúságod
S halálos nagypénteked
Él tovább és lüktet
Ereinkben, valónkban
A mikép te mondtad: Jól van.”

A „megmunkálandó kő” az ember jelképe, így a szabadkőműves szertartásos összejövetelek alkalmából, az oltár két oldalán elhelyezett szimbólum.

Mind operatív- és allegorikus szempontból beszélhetünk durva- és csiszolt, ha jobban tetszik vagy faragatlan és faragott kőről.
Míg a durva kő szimbolizálja az embert, aki nem igyekszik saját tökéletesítésére – továbbá a még alacsonyabb beavatási szinten álló, inas jelképeként is alkalmazott, akinek viszont a csiszolás a feladata – addig a
csiszolt kő a törekvő szellemi szférát hivatott jellemezni.

MOST PEDIG KERESSÉTEK „LOVIK” MEGMUNKÁLATLAN SÍREMLÉKÉT.
A 19/1-1-36-OS PARCELLÁBAN TALÁLJÁTOK.
MI LOVIK KERESZTNEVE?

MIKLÓS
SÁNDOR
GÁBOR
KÁROLY
KÁLMÁN
ANDRÁS
KÁZMÉR
KONRÁD
KORNÉL
            `,
      images: [
        {
          url: require("../../assets/images/freemansory/10 Bányai elemér sírkép kép 10.jpg"),
        },
      ],
      solutionRegExp: /^(KÁROLY|KAROLY)$/,
      solutionKey: "KÁROLY",
      hints: [
        {
          id: 0,
          text:
            "OLYAN SÍREMLÉKET KERESSETEK, AMI NINCS MEGMUNKÁLVA CSAK EGY NÉV VAN RAJTA",
        },
        {
          id: 1,
          text: "A FARAGVÁNY HÁROM SZINTRE VAN OSZTVA",
        },
        {
          id: 2,
          text: 'A megoldás "KÁROLY"',
        },
      ],
    },
    {
      id: 8,
      type: PuzzleType.STANDARD,
      shortTitle: "Lovik",
      title: `Lovik`,
      description: `Lovik károly 24 éves korában jelentkezett a Demokratia páholyba. A felvételi kérdésekre adott válaszai a következők voltak:

      „Mit keres közöttünk? 
-Nemes eszméket, a melyek az emberiség és a magam fölvilágosodottságát elémozdítják, eszméket, a melyek a jó, tiszta és becsületes fogalmát mindenképpen megvalósíták. Keresem önök között az igazságot, a humanizmust, a felebaráti szeretetet, a tiszta jellem minden megnyilatkozását. […]

Mi módon kíván részt venni működésünkben? 
-Szóval, irásban és tettben.”
1898 és 1909 között mintegy százhuszonkét különböző szintű (I–II– III.) és jellegű (dísz-, gyász-, különleges, választó, díjemelő stb.) páholymunkán vett részt.
1909 őszén törölték a páholytagok névsorából, mivel egyre ritkábban vett részt a páholymunkákban. Feltehetőleg a szabadkőművesség 1905 utáni radikalizálódása vette el kedvét a további aktivitástól. Érdekes, hogy felesége viszont továbbra is részt vett a női rendezvényeken.
            
EGYRE TÖBBET TUDTOK A SZABADKŐMŰVESEKRŐL, GRATULÁLUNK!

MOST MENJETEK A 34/1-3-19 PARCELLÁHOZ ÉS KERESSÉTEK MEG AZT AKIÉRT SZEMÉLYESEN JÖTT EL EGY FÖLDMŰVES HOGY ELVIGYE MAGÁVAL, Ő PEDIG LEVESZI SAPKÁJÁT ÉS NEM BOHÓCKODIK TOVÁBB. 

A „kasza”, mint jelkép a szabadkőművesek esetében nem a halál eszköze, hanem a természet átalakító gondoskodásának jelképe, a természet ajándékának beavatását jelképezi.

Amíg sétáltok, addig egy kis érdekesség:
A szabadkőművesség egyesek szerint nagyon régi gyökerekre vezethető vissza, sokak szerint már az ókorban is léteztek, mások a Templomosokig vezetik vissza a kezdeteket. Ha úgy fogjuk fel a kérdést, hogy a mindenkori hatalmasságok elől rejtőzködő szervezeteket keresünk a történelemben, akkor lehetséges akár az ókorig is visszamennünk, ám a valódi szabadkőművesség a felvilágosodással született meg a 19. század végén -18. század elején.

HOGY HÍVJÁK ŐT?
`,
      images: [
        {
          url: require("../../assets/images/freemansory/11. Lovik Károly síremlék 11.jpg"),
        },
      ],
      solutionRegExp: /^(VÍZVÁRI GYULA|VIZVÁRI GYULA|VIZVARI GYULA)$/,
      solutionKey: "VÍZVÁRI GYULA",
      hints: [
        {
          id: 0,
          text: "A SÍRJÁN KÉT ALAK LÁTHATÓ",
        },
        {
          id: 1,
          text: "AZ EGYIK ALAK EGY KASZÁT FOG A KEZÉBEN",
        },
        {
          id: 2,
          text: 'A megoldás "VÍZVÁRI GYULA"',
        },
      ],
    },
    {
      id: 9,
      type: PuzzleType.STANDARD,
      shortTitle: "Durbints",
      title: `Durbints`,
      description: `Vízvári Gyula magyar színész, rendező, a Nemzeti Színház volt örökös tagja, az Országos magyar királyi Zeneakadémia tanára, a Ferenc József-rend lovagja.
Pályafutását Veszprémben kezdte 1859-ben, itt Csabay Pál társulatában lépett fel, később Debrecenben játszott. 1861 decemberétől 1864-ig a Budai Népszínház foglalkoztatta. ’66 őszétől ’72-ig vezető színész volt a debrecen–nagyváradi társulatban, 1872 nyarán Miklósy Gyula meghívására került Pestre, 1873-ban pedig Szigligeti Ede hívta a Nemzeti Színházhoz.
1905-ben vonult nyugállományba, ekkor választották meg a színház örökös tagjává. 1889-től lett rendező, és egyúttal a drámabíráló bizottság tagja. Oktatott operajátszást a Zeneakadémián, de komikus szerepekben kitűnő volt. 
Legutolsó föllépése 1907. szeptember 22-én volt, amikor is  Durbints sógor híres szerepét játszotta. Azután nem lépett többé színpadra.

KITŐL IDÉZÜNK?

„A játszótársam mond akarsz-e lenni
Akarsz-e mindig, mindig játszani
Akarsz-e együtt a sötétbe menni
Gyerek szívvel fontosnak látszani
Nagy komolyan az asztalfőre ülni
Borból vízből mértékkel tölteni
Gyöngyöt dobálni, semminek örülni
Sóhajtva rossz ruhákat ölteni
Akarsz, akarsz-e játszani
Akarsz-e játszani mindent mi élet
Havas telet és hosszú, hosszú őszt
Lehet-e némán teát inni véled
Rubint teát és sárga páragőzt”

HA MEGVAN, MENJETEK A 42-1-98 PARCELLÁHOZ ÉS ÍRJÁTOK BE A NEVÉT. 
            `,
      images: [
        {
          url: require("../../assets/images/freemansory/12 Vízvári Gyuláról kép 12.jpg"),
        },
      ],
      solutionRegExp: /^(KOSZTOLÁNYI DEZSŐ|KOSZTOLANYI DEZSO|KOSZTOLÁNYI DEZSÖ)$/,
      solutionKey: "KOSZTOLÁNYI DEZSŐ",
      hints: [
        {
          id: 0,
          text: "EGYEDÜL ÁLLDOGÁL A SÍRJA FELETT",
        },
        {
          id: 1,
          text: "LEGHÍRESEBB BARÁTJA KARINTHY FRIGYES",
        },
        {
          id: 2,
          text: 'A megoldás "KOSZTOLÁNYI DEZSŐ"',
        },
      ],
    },
    {
      id: 10,
      type: PuzzleType.STANDARD,
      shortTitle: "Emil",
      title: `Emil`,
      description: `Kosztolányi az 1910-es évektől kezdve a Magyarországi Symbolikus Nagypáholy által alapított ’Világ című’ lap munkatársa. 
Kosztolányi 1916. november 30-án, I. Ferenc József temetése napján este 8 órai kezdettel, császár és király emlékére tartott szabadkőműves gyászmunkán (gyászünnepélyen) olvasta fel "Láncvers" című költeményét. A megemlékező vers nyomtatásban is megjelent a ’Kelet’ 1916. decemberi számában. Kosztolányi hozzá fűzött megjegyzésének "Alkalmi vers – a szabadkőműveseknek fölolvastam F.I. halálakor." téves kiolvasása következtében mindmáig F. J. (Ferenc József) helyett, valamely F. I. monogramú szabadkőműves társ temetésére írt költeménynek tartották.
Kosztolányi a Tanácsköztársaság bukása után 1921-ig a szélsőjobboldali ’Új Nemzedék’ című lap Pardon rovatának szerkesztőjeként éles hangú antiszemita és szabadkőműves ellenes cikkeket írt, megtagadva ezzel saját szabadkőműves múltját is.

Induljatok Ábrányi Emil sírjához: 37/2-1-33 Parcella

Amíg keresitek addig egy kis érdekesség:
A kard az igazságot és a hatalmat jelképezi, a küzdelem jele. A kardok, ezek közül is a lángpallos a legfontosabb, illetve alapvető kelléke a szabadkőműves munkáknak. A kard szertartásbeli használata arra emlékeztet, hogy a szabadkőműves, a lovagrendből is eredeztethető. Avatások során az avatás a lángpallos alatt történik. Kardot használtak és használnak ma is a lovaggá ütéshez. A fényes penge a gondolkodást, az eszme és cselekedet tisztaságát jelképezi. A hagyományokhoz híven ma is fegyveresen, karddal őrzi a kapuőr a páholy ajtaját vagy kapuját a hivatalnok elől, ami egyuttal az összejövetel titkosságára utal. A rituális munkában az inas avatásnál is használják.  Egyszer, amikor az avató a mesterek az inas meztelen mellének szegezik, jelezve, hogy ha rossz szándékkal közeledik, kiűzetik.

HÁNY OSZLOP LÁTHATÓ ÁBRÁNY EMIL SÍRJÁN?
            `,
      images: [
        {
          url: require("../../assets/images/freemansory/13 Kosztolányiról kép 13.jpg"),
        },
      ],
      solutionRegExp: /^(2)$/,
      solutionKey: "2",
      hints: [
        {
          id: 0,
          text: "1-10 KÖZÖTTI SZÁM",
        },
        {
          id: 1,
          text: "1-5 KÖZÖTTI SZÁM",
        },
        {
          id: 2,
          text: 'A megoldás "2"',
        },
      ],
    },
    {
      id: 11,
      type: PuzzleType.STANDARD,
      shortTitle: "Klapka",
      title: `Klapka`,
      description: `A szabadkőművesség eredetmítoszainak tekintett szövegek utalásaiban, a bibliai időkről, azon belül is először Lamechról beszél, aki jóval az özönvíz előtt élt, és akinek két felesége volt: Adah és Zillah. Adahtól két fia született: Jabal és Jubal. Az idősebb fiú, Jabal a geometria és a kőművesség ősatyja volt, Káin mellett dolgozott, és ő irányította azokat a kőműveseket, akik az első várost, Enókhot építették. Öccse, Jubal a zene művészetének (tudományának) megalapítójaként föltalálta az orgonát és a trombitát, továbbá észrevette a hangokban rejlő égi harmóniát. 
Lamech másik felesége, Zillah, szintén két gyermeket szült. A fiúgyermek, Tubal Cain a kovácsok és a fémművesség ősatyja lett, a lánygyermek, Naamah nevéhez pedig a női kézműves szakmák fűződnek.
Így Lamech gyermekei, a három fiú és egy lány hozták tehát létre és terjesztették el a földön a kézművesmesterséget és annak minden tudományát. Tudásukat pedig – amely magában foglalta a hét szabad művészet minden tudományát – két hatalmas kőoszlopra vésték föl. Azonban a két kőoszlop az özönvíz idején elveszett (az eredetlegendák itt Noé bárkájáról is beszámolnak), sok-sok évvel később az egyiket Pithagorasz, a másikat a filozófus Hermész találta meg. Pithagorasz a számok és a mértan tudományában rejtette el Lamech gyermekeinek bölcsességét, Hermész pedig – aki minden valószínűség szerint Hermész Triszmegisztosszal, a hermetikus hagyományok atyjával azonosítható – szimbolikus mondatokban, melyek valódi értemét csak a beavatottak ismerik.
           
Ábrányi Emil pest-belvárosi római katolikus plébánián keresztelték 1851. január 2-án. Tanulmányai végeztével írói hivatásának élt, és több lapnál tárcaíró és színikritikus volt. Első verse 1866-ban jelent meg. 1885-ben a ’Koszorú’ című hetilap szerkesztője. 1873-tól a ’Pesti Napló’, 1879-től a ’Magyarország’, 1896-tól a ’Budapesti Napló’, 1904-től 1907-ig A ’Nap’ belső munkatársa volt.

MOST KERESSÉTEK MEG KLAPKA GYÖRGY NYUGHELYÉT.
VALAHOL A DEÁK FERENC MAUZÓLEUMA KÖRÜLI SÍROK KÖZÖTT TALÁLJÁTOK.
EZEN A SÍRON RENGETEG SZABADKŐMŰVES JELKÉP FELFEDEZHETŐ. 
TUDJÁTOK MELYEK LEHETNEK EZEK!
OLVASSÁTOK A JELEKET LENTRŐL FELFELÉ ÉS ÍRJÁTOK BE A SZÁMAIKAT HELYES SORRENDBEN.

KARD-1
DERÉKSZÖG-2
KÖNYV-3
KÖRZŐ-4
MEGMUNKÁLATLAN KŐ-5
`,
      images: [
        {
          url: require("../../assets/images/freemansory/14 Oszlopokról kép 14.jpg"),
        },
        {
          url: require("../../assets/images/freemansory/15 Ábrányi Emilről kép 15.jpg"),
        },
      ],
      solutionRegExp: /^(52413)$/,
      solutionKey: "52413",
      hints: [
        {
          id: 0,
          text: "Keressétek a szimbólumokat",
        },
        {
          id: 1,
          text: "Az egész sirt nézzétek",
        },
        {
          id: 2,
          text: 'A megoldás "52413"',
        },
      ],
    },
    {
      id: 12,
      type: PuzzleType.STANDARD,
      shortTitle: "Szimbólumok",
      title: `Szimbólumok`,
      description: `„A három világosság” a Biblia, a körző és a derékszög.
A körző és a derékszög Bibliára helyezése minden fokon egyfajta eskü az írásra, nem pedig isten alárendelése a mozgalomnak.

Körző: A világegyetemben ható központosító tudat elvét képviseli. A körző a megmérettetés és a pontosság eszköze, a szellemet és értelmet jelképezi, amely a pszichével szembenálló aspektusaként van jelen.

Derékszög, más néven a szögmérő: Az emberi lélek szimbóluma, mert a lélek megteremtésekor egyenes. A világegyetemet átható tárgyilagos rend elvét jeleníti meg. Az inas lábtartása is a derékszöget jelképezi.

A biblia: Az ősi tudást jelenti, a hagyományokat képviseli, ezen túl mítoszok gyűjteménye.

Klapka György 1838 májusában lépett hadapródként az 5. (Bervaldo) tüzérezredbe. ’42-ben apja, de főként nagybátyja befolyását latba vetve először a magyar királyi nemesi testőrségbe, végül a bécsi gárdába sikerült bejutnia. A Bécsben töltött évek nagy jelentőséggel bírtak további katonai karrierjében, hiszen ott főhadnaggyá léptették elő, és többek között olyan tanárai, kiképzői voltak, mint a későbbi vértanú, Vécsey Károly édesapja. A gárdában ismerkedett meg Görgey Artúrral, akivel ettől kezdve szoros barátság kötötte össze.
1847-ben főhadnagyi rangja megtartása mellett leszerelt.

’71-ben Klapka György, Türr István és Andrássy Gyula társaságában a magyarországi Nagy Oriens szabadkőműves nagypáholy alapító tagjai között van.


MOST PEDIG KERESSÉTEK A „MAGYAROK MÓZESÉNEK” MAUZÓLEUMÁT.
HOGY HÍVJÁK ŐT?
            `,
      images: [
        {
          url: require("../../assets/images/freemansory/16 Biblia, körző, derékszög kép 16.jpg"),
        },
        {
          url: require("../../assets/images/freemansory/17 Klapka György kép 17.jpg"),
        },
      ],
      solutionRegExp: /^(KOSSUTH LAJOS)$/,
      solutionKey: "KOSSUTH LAJOS",
      hints: [
        {
          id: 0,
          text:
            "MÁIG EGYIKE AZOKNAK, AKIK A MAGYAR NÉP EMLÉKEZETÉBEN LEGINKÁBB MEGTESTESÍTIK AZ 1848–1849-ES FORRADALMAT ÉS SZABADSÁGHARCOT, SZÉCHENYIVEL ÉS PETŐFIVEL EGYÜTT. ÚJSÁGÍRÓI ÁLNEVE: DEREGNYEI.",
        },
        {
          id: 1,
          text: "AZT ÜZENTE: „ELFOGYOTT A REGIMENTJE”.",
        },
        {
          id: 2,
          text: 'A megoldás "KOSSUTH LAJOS"',
        },
      ],
    },
    {
      id: 13,
      type: PuzzleType.STANDARD,
      shortTitle: "Szimbólumok",
      title: `Szimbólumok`,
      description: `Kossuth Lajos már 1848-ban érdeklődött a szabadkőművesség iránt, jelezte is belépési szándékát a Pesten Thoma Mihály Ágoston által megalakítani kívánt páholyba. Mikor azonban kiderült, hogy a páholy az ő nevét viselné – úgy, mint „Kossuth Lajos a dicső fény hajnalához” – akkor szándékáról lemondott, különösen, hogy a körülmények ezt egyébként sem tették lehetővé (az osztrák csapatok közeledése, majd Pest elfoglalása).
A szabadkőműves testvériségbe végül 1852-es amerikai látogatása során, Cincinattiban vették fel, Bethlen Gergely, Hajnik Pál, Nagy Péter, Strasser Lajos szabadságharcos társaival együtt. Rendkívüli sürgősséggel legény, majd mesterfokba avatták őket, sőt állítólag a Royal Arch (Királyi Boltív) fokait is megkapták. Az amerikai út során Kossuth több városban is meglátogatta a helyi szabadkőműves páholyokat, ahol nagy tisztelettel fogadták és ünnepelték, mint a magyar szabadság hősét.
Olaszországi emigrációja során szabadkőműves tevékenységéről, a páholyok látogatásáról nem tudunk, mindenestere halálakor a torinói Dante Alighieri szabadkőműves páholy nagy gyászszertartást rendezett neki. A hazai páholyok is gyászmunkákat tartottak Kossuth Lajos halála után, illetve később is rendszeresen megemlékeztek szabadkőműves testvérükről.

HA JÓL KÖRÜLNÉZTEK, TALÁLNI FOGTOK TÖBB SZABADKŐMŰVES SZIMBÓLUMOT KOSSUTH MAUZÓLEUMA KÖRNYÉKÉN.  A KÖVETKEZŐ SZIMBÓLUMOKNAK A KÖVETKEZŐ SZÍNEI VANNAK:

FEKETE – AZ ÉJSZAKA, A SÖTÉTSÉG
KÉK – AZ IGAZSÁG
EZÜST – HOLD
AZÚRKÉK – FŐMESTER
ARANY – NAP
VÖRÖS – ERŐ
FEHÉR – TISZTASÁG

Melyik színt lehet megtalálni a műemléken, jellemző szimbólumok képében?
            `,
      images: [
        {
          url: require("../../assets/images/freemansory/18 Kossuth Lajos kép 18.jpg"),
        },
        {
          url: require("../../assets/images/freemansory/19 Kossuth Oklevele 19.jpg"),
        },
      ],
      solutionRegExp: /^(ARANY)$/,
      solutionKey: "ARANY",
      hints: [
        {
          id: 0,
          text: "KÖVESSÉTEK A CSILLAGOKAT.",
        },
        {
          id: 1,
          text: "AMI A FELSŐ KRIPTÁRA VIGYÁZ, AZT KERESITEK.",
        },
        {
          id: 2,
          text: 'A megoldás "ARANY"',
        },
      ],
    },
    {
      id: 14,
      type: PuzzleType.STANDARD,
      shortTitle: "Csillag",
      title: `Csillag`,
      description: `A „lángoló csillag” az emberi kutató, igazságot kereső, logikus gondolkodás jelképe, de magyarázzák az élet őserőinek, a fénynek és a melegnek a szimbólumaként is. A XIX. század végétől általában a főmester jelképeként szolgált.
A skót rítusban a fény megjelenését jelképezi, a szellemszikra szimbólumának is tartják. Az angol nagypáholy álláspontja szerint a lángoló csillag magát a napot jelképezi, az égitestet, amely a földet sugaraival megvilágítva áldást hoz az emberiségre, fényt és életet teremtve. A német szabadkőművességben az elme világosságát szimbolizálja, melyet a világegyetem Nagy Építőmesterétől kapott az ember, hogy segítségével megkülönböztessük a valóságot a látszattól, a jót és a rosszat, az igazat és hamist.

IRJÁTOK BE, HOGY VÉGE A FOLYTATÁSHOZ!
            `,
      images: [
        {
          url: require("../../assets/images/freemansory/20 Kossut sirjy nap kép 20.jpg"),
        },
        {
          url: require("../../assets/images/freemansory/21 Lezáró kép 21.jpg"),
        },
      ],
      solutionRegExp: /^(VEGE|VÉGE)$/,
      solutionKey: "VEGE",
      hints: [
        {
          id: 0,
          text: 'A megoldás "VÉGE"',
        },
      ],
    },
    {
      id: 15,
      type: PuzzleType.END,
      shortTitle: "Vége",
      title: "Gratulálunk!",
      description: `RATULÁLUNK!  ÜGYESEK VOLTATOK!
Reméljük jól éreztétek magatok, illetve, hogy sok új információval szolgáltunk és sikerült minnél több dolgot megjegyeznetek, így közelebb kerülve a Magyar szabadkőművesek misztériumához 
Köszönjük, hogy velünk játszottatok, további játékokért keressetek minket vagy nézzetek körül a https://crazypubtour.hu/ oldalunkon
            `,
      url: "https://crazypubtour.hu/",
    },
  ],
};
