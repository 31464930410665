import React, { useEffect, Fragment } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import MobileStepper from "@material-ui/core/MobileStepper";
import Button from "@material-ui/core/Button";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { I18nService } from "../services/i18n-service";

type ImageRes = {
  url: string;
  copyright?: string;
};

type ImageCarouselProps = {
  images: Array<ImageRes>
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    alignSelf: "center"
  },
  header: {
    display: "flex",
    alignItems: "center",
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default
  },
  img: {
    height: "100%",
    display: "block",
    overflow: "hidden",
    width: "100%"
  },
  copyright: {
    color: "#666",
    textAlign: "center"
  }
}));

const ImageCarousel: React.FC<ImageCarouselProps> = ({images}) => {
  const classes = useStyles({});
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);

  function handleNext() {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  }

  function handleBack() {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  }

  function handleStepChange(step) {
    setActiveStep(step);
  }

  useEffect(() => {
    setActiveStep(0);
  }, [images]);

  return (
    <div className={classes.root}>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {images.map((image, index) => {
          const imageUrl = image.url ? image.url : (image as any); // Legacy image defs without copyright

          return (
            <div key={imageUrl}>
              {Math.abs(activeStep - index) <= 2 ? (
                <Fragment>
                  <img className={classes.img} src={imageUrl} alt={imageUrl} />
                  {image.copyright && <div className={classes.copyright}>{image.copyright}</div>
                  }
                </Fragment>
              ) : null}
            </div>
          );
        })}
      </SwipeableViews>
      <MobileStepper
        steps={images.length}
        position="static"
        variant="text"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === images.length - 1}
          >
            {new I18nService().getTranslationFor('next')}
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            {new I18nService().getTranslationFor('back')}
          </Button>
        }
      />
    </div>
  );
};

export default ImageCarousel;
