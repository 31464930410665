export interface Coordinate{
  lat: number;
  lng: number
}

function toRad(input: number){
  return input * Math.PI / 180;
}

function toDeg(input) {
  return input * 180 / Math.PI;
}

/**
 * Calculates the distance between two coordinates.
 * 
 * @param from Starting coordinate.
 * @param to Coordinate of destination.
 * @returns The distance between the two coordinates in meters.
 */
export function calculateDistance(from: Coordinate, to: Coordinate): number{
  let radius = 6371;

  let x1 = from.lat - to.lat;
  let dLat = toRad(x1);
  let x2 = from.lng - to.lng;
  let dLng = toRad(x2);
  let a = Math.sin(dLat/2) * Math.sin(dLat/2) + 
  Math.cos(toRad(to.lat)) * Math.cos(toRad(from.lat)) * 
  Math.sin(dLng/2) * Math.sin(dLng/2);
  let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
  return radius * c * 1000;
}

export function calculateBearing(from: Coordinate, to: Coordinate){
  let startLat = toRad(from.lat);
  let startLng = toRad(from.lng);
  let destLat = toRad(to.lat);
  let destLng = toRad(to.lng);

  let y = Math.sin(destLng - startLng) * Math.cos(destLat);
  let x = Math.cos(startLat) * Math.sin(destLat) -
        Math.sin(startLat) * Math.cos(destLat) * Math.cos(destLng - startLng);
  let bearing = Math.atan2(y, x);
  bearing = toDeg(bearing);
  return (bearing + 360) % 360;
}