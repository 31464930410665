import React from "react";
import {
  createMuiTheme,
  MuiThemeProvider,
  CssBaseline,
} from "@material-ui/core";
import Router from "./Router";
import configureStore from "./redux/configureStore";
import { Provider } from "react-redux";

const theme = createMuiTheme({
  spacing: 8,
  palette: {
    type: "dark",
    primary: {
      main: "#f55555"
    },
    background: {
      default: "#292929"
    }
  }
});

const store = configureStore();

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Router />
      </MuiThemeProvider>
    </Provider>
  );
};

export default App;
