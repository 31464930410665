import { API_EXECUTE_MUTATION_SUCCESS, API_SESSION_FETCH, API_SESSION_ERROR, API_SESSION_SUCCESS, API_SESSION_INVALID, API_SESSION_NOCODE, API_SOLVE_PUZZLE_SUCCESS, API_LOG_OUT } from "./apiActionTypes";

const initialState = {
    loading: false,
    error: false,
    done: false,
    session: {
        id: 0,
        puzzleId: 0,
        tourId: 0,
        createdAt: "",
        startedAt: "",
        finishedAt: "",
        hintCount: 0,
        missedCount: 0,
        voucherCode: ""
    },
};

const apiReducer = (state = initialState, action) => {
    switch (action.type) {
        case API_SESSION_FETCH:
            return {
                ...state,
                loading: true,
                error: false,
                done: false,
            };
        case API_SESSION_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                done: false,
            };
        case API_SESSION_INVALID:
            return {
                ...state,
                loading: false,
                error: true,
                done: false,
            };
        case API_SESSION_SUCCESS:
            return {
                ...state,
                loading: false,
                done: true,
                error: false,
                session: {
                    ...action.payload
                }
            };
        case API_SESSION_NOCODE:
            return {
                ...state,
                loading: false,
                done: false,
                error: false,
            };
        case API_SOLVE_PUZZLE_SUCCESS:
            return {
                ...state,
                session: {
                    ...state.session,
                    puzzleId: action.payload.puzzleId
                }
            };
        case API_EXECUTE_MUTATION_SUCCESS:
            return {
                ...state,
                session: {
                  ...state.session,
                  ...action.payload
                }
            };

        case API_LOG_OUT:
            return initialState;
        default:
            return state;
    }
}

export default apiReducer
