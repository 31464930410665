import { Box } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React, { Fragment } from "react";
import { useDispatch } from "react-redux";
import { PuzzleStandard } from "../../assets/tours/types";
import { Alert, useAlert } from "../../components/Alert";
import ImageCarousel from "../../components/ImageCarousel";
import SolutionForm from "../../components/SolutionForm";
import {
  increaseHintCount,
  increaseMissedCount,
  logAnswer
} from "../../redux/api/apiActions";
import { I18nService } from "../../services/i18n-service";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      margin: theme.spacing(1),
      padding: theme.spacing(1),
      flexGrow: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start"
    },
    footer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column"
    },
    text: {
      whiteSpace: "pre-line"
    },
    helpButton: {
      marginBottom: theme.spacing(2)
    },
    hr: {
      height: 1,
      backgroundColor: "#fff",
      opacity: 0.5,
      width: 200,
      margin: theme.spacing(2)
    }
  })
);

type StandardPuzzleProp = {
  puzzle: PuzzleStandard;
  onComplete: Function;
};

const StandardPuzzle: React.FC<StandardPuzzleProp> = ({
  puzzle,
  onComplete
}) => {
  const classes = useStyles({});
  const { showAlert, alertComponentProps } = useAlert();
  const [trialCount, setTrialCount] = React.useState(1);
  const [usedHintCount, setUsedHintCount] = React.useState(0);
  const dispatch = useDispatch();

  // Maybe replace with usePuzzle custom hook? or HOC?
  const handleSubmit = (_answer: string) => {
    dispatch(logAnswer(_answer));

    if (puzzle.solutionRegExp.test(_answer)) {
      onComplete();
      setUsedHintCount(0);
      setTrialCount(0);
    } else {
      dispatch(increaseMissedCount());
      showAlert({
        title: `${_answer} - ${new I18nService().getTranslationFor('incorrect_answer')}`,
        description: new I18nService().getTranslationFor('incorrect_answer_description', {
          trialCount: trialCount.toString()
        })
      });
    }

    setTrialCount(trialCount + 1);
  };

  const showHint = () => {
    showAlert({
      title: `${new I18nService().getTranslationFor('help')} - #${usedHintCount + 1}`,
      description: puzzle.hints[usedHintCount].text,
      imgUrl: puzzle.hints[usedHintCount].imgUrl
        ? puzzle.hints[usedHintCount].imgUrl
        : false
    });

    if (puzzle.hints.length - 1 !== usedHintCount) {
      // Not all hints have been used
      setUsedHintCount(usedHintCount + 1);
      dispatch(increaseHintCount());
    }
  };

  return (
    <Fragment>
      <Box className={classes.content}>
        {puzzle.images && <ImageCarousel images={puzzle.images} />}
        <Typography
          variant="h5"
          color="primary"
          component="h2"
          className={classes.text}
        >
          {puzzle.title}
        </Typography>
        <Typography
          variant="body1"
          color="textSecondary"
          component="p"
          className={classes.text}
        >
          {puzzle.description}
        </Typography>
      </Box>
      <Box className={classes.footer}>
        <SolutionForm
          onSubmit={guess => handleSubmit(guess)}
          solutionKey={puzzle.solutionKey}
        />
        <>
          <Box className={classes.hr} />
          <Button
            size="large"
            variant="contained"
            color="primary"
            className={classes.helpButton}
            onClick={e => showHint()}
          >
            {new I18nService().getTranslationFor('help')}
          </Button>
        </>
      </Box>
      <Alert {...alertComponentProps} />
    </Fragment>
  );
};

export default StandardPuzzle;
