import React, { useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { Box, TextField } from "@material-ui/core";
import { I18nService } from "../services/i18n-service";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexGrow: 1,
    },
    solution: {
      margin: theme.spacing(1),
      flexGrow: 1,
      width: 200,
    },
    sendButton: {
      margin: theme.spacing(1),
    },
  })
);

type SolutionFormProps = {
  onSubmit: (solution: string) => void;
  solutionKey: string;
};

const generatePlaceHolder = (solution) => {
  let wordCharCounts = [];
  let wordCount = 0;
  let wordCharCounter = 0;
  let charIterator = 0;
  while (charIterator < solution.length) {
    if (solution[charIterator] !== " ") {
      wordCharCounter++;
    }

    if (
      solution[charIterator] === " " ||
      charIterator === solution.length - 1
    ) {
      wordCharCounts.push(wordCharCounter);
      wordCount++;
      wordCharCounter = 0;
    }

    charIterator++;
  }

  let spaceHolder = "";
  for (let i = 0; i < wordCharCounts.length; i++) {
    for (let n = 0; n < wordCharCounts[i]; n++) {
      spaceHolder += "\u2610";
    }
    spaceHolder += "  ";
  }
  return spaceHolder;
};

const SolutionForm: React.FC<SolutionFormProps> = ({
  onSubmit,
  solutionKey,
}: SolutionFormProps) => {
  const classes = useStyles({});
  const [solution, setSolution] = useState("");

  const spaceHolder = generatePlaceHolder(solutionKey);

  return (
    <Box className={classes.root}>
      <TextField
        label={new I18nService().getTranslationFor('solution')}
        type="search"
        className={classes.solution}
        variant="outlined"
        margin="dense"
        onChange={(e) => {
          setSolution(e.target.value.toUpperCase());
        }}
        placeholder={spaceHolder}
        value={solution}
        autoComplete="off"
      />
      <Button
        size="large"
        variant="contained"
        className={classes.sendButton}
        onClick={(e) => {
          onSubmit(solution);
          setSolution("");
        }}
      >
        {new I18nService().getTranslationFor('submit')}
      </Button>
    </Box>
  );
};

export default SolutionForm;
