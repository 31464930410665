import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import ReactCodeInput from "react-code-input";
import { Button, Box, CircularProgress } from "@material-ui/core";
import { API_SESSION_FETCH } from "../redux/api/apiActionTypes";
import { fetchSession } from "../redux/api/apiActions";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "100vh",
      backgroundImage: require("../assets/images/bg.jpg")
    },
    welcomeTitle: {},
    welcomeText: {
      display: "flex"
    },
    voucherCode: {
      marginTop: 20,
      marginBottom: 20,
      display: "flex"
    },
    sendButton: {
      display: "flex"
    },
    alertComponent: {}
  })
);

const reactCodeInputProps = {
  inputStyleInvalid: {
    margin: "4px",
    MozAppearance: "textfield",
    width: "40px",
    borderRadius: "5px",
    fontSize: "30px",
    height: "80px",
    paddingLeft: "7px",
    backgroundColor: "#000",
    color: "#ff595f",
    border: "5px solid #000"
  },
  inputStyle: {
    margin: "4px",
    MozAppearance: "textfield",
    width: "40px",
    borderRadius: "5px",
    fontSize: "30px",
    height: "80px",
    paddingLeft: "7px",
    backgroundColor: "#ff595f",
    color: "#ffffff",
    border: "5px solid #ff595f"
  }
};

const VoucherScreen: React.FC = () => {
  const classes = useStyles({});

  const [voucherInput, setVoucherInput] = React.useState("");
  const { loading, error } = useSelector(state => state.apiReducer);
  const dispatch = useDispatch();

  const isVoucherGoodFormat = (_voucherCode: string) => {
    return _voucherCode.length === 6;
  };

  const checkVoucherCode = (_voucherCode: string) => {
    dispatch(fetchSession(_voucherCode));
  };

  return (
    <Box className={classes.root}>
      <Typography
        className={classes.welcomeTitle}
        variant="h5"
        color="primary"
        component="h2"
      >
        Készen állsz?
      </Typography>
      <Box className={classes.welcomeText}>
        <Typography
          variant="body1"
          color="textSecondary"
          component="p"
          className={classes.welcomeText}
        >
          Add meg az emailben kapott kódod a kezdéshez
        </Typography>
      </Box>
      <ReactCodeInput
        autoFocus={false}
        name="reactCodeInput"
        className={classes.voucherCode}
        type="text"
        value={voucherInput}
        fields={6}
        {...reactCodeInputProps}
        isValid={isVoucherGoodFormat(voucherInput)}
        onChange={(value: string) => {
          setVoucherInput(value.toUpperCase());
        }}
      />
      {loading && <CircularProgress />}
      {!loading && (
        <Button
          className={classes.sendButton}
          size="large"
          variant="contained"
          color="primary"
          onClick={e => checkVoucherCode(voucherInput)}
        >
          Kezdhetjük
        </Button>
      )}
      {error && (
        <Typography
          variant="body1"
          color="textSecondary"
          component="p"
          className={classes.welcomeText}
        >
          Sajnos a megadott kód érvénytelen
        </Typography>
      )}
    </Box>
  );
};

export default VoucherScreen;
