import React, { useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import StandardPuzzle from "./puzzles/StandardPuzzle";
import CounterPuzzle from "./puzzles/CounterPuzzle";
import EndPuzzle from "./puzzles/EndPuzzle";
import StartPuzzle from "./puzzles/StartPuzzle";
import {
  PuzzleType,
  PuzzleStandard,
  PuzzleCounter,
  PuzzleEnd,
  GpsTrackerPuzzle
} from "../assets/tours/types";
import { getPuzzleByTourAndId, getPuzzles } from "../assets/tours";
import { Box } from "@material-ui/core";
import ProgressBar from "../components/ProgressBar";
import { useDispatch, useSelector } from "react-redux";
import { solvePuzzle, logOut, setEndTime } from "../redux/api/apiActions";
import LanguageSelector from "./puzzles/LanguageSelector";
import LanguageSwitcher from "./puzzles/LanguageSwitcher";
import GpsTracker from "./puzzles/GpsTracker";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      height: "100vh"
    },
    header: {}
  })
);

const PuzzleScreen: React.FC = () => {
  const classes = useStyles({}); // Workaround for strictNullCheck issue with material ui
  const activePuzzle = useSelector(state => {
    const { session } = state.apiReducer;
    return getPuzzleByTourAndId(session.tourId, session.puzzleId);
  });
  const session = useSelector(state => {
    const { session } = state.apiReducer;
    return session;
  });
  const [currentPuzzle, updatePuzzle] = useState(activePuzzle);
  const tourId = useSelector(state => {
    const { session } = state.apiReducer;
    return session.tourId;
  });
  const dispatch = useDispatch();

  const onComplete = () => {
    dispatch(solvePuzzle());
    dispatch(setEndTime());
  };

  const onEnd = () => {
    dispatch(logOut());
  };

  const router = (puzzleType: PuzzleType) => {
    switch (puzzleType) {
      case PuzzleType.STANDARD:
        return (
          <StandardPuzzle
            puzzle={activePuzzle as PuzzleStandard}
            onComplete={onComplete}
          />
        );
      case PuzzleType.START:
        return <StartPuzzle onComplete={onComplete} />;
      case PuzzleType.LANGUAGE_SELECTOR:
        return <LanguageSelector onComplete={onComplete}></LanguageSelector>;
      case PuzzleType.GPS_TRACKER:
        return <GpsTracker puzzle={activePuzzle as GpsTrackerPuzzle} onComplete={onComplete}></GpsTracker>;
      case PuzzleType.COUNTER:
        return (
          <CounterPuzzle
            puzzle={activePuzzle as PuzzleCounter}
            onComplete={onComplete}
          />
        );
      case PuzzleType.END:
        return <EndPuzzle puzzle={activePuzzle as PuzzleEnd} onEnd={onEnd} />;
      default:
        return null;
    }
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.header}>
        <ProgressBar
          value={activePuzzle.id + 1}
          max={getPuzzles(tourId).length}
        />
      </Box>
      <LanguageSwitcher onLanguageChange={() => {
        updatePuzzle(getPuzzleByTourAndId(session.tourId, session.puzzleId));
      }}></LanguageSwitcher>
      {router(activePuzzle.type)}
    </Box>
  );
};

export default PuzzleScreen;
