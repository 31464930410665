import { cloneDeep } from "lodash";
import { I18nService } from "../../services/i18n-service";
import { ballOfPuskasTour } from "./ballOfPuskasTour";
import { demoTour } from "./demoTour";
import { freemansoryTour } from "./freemansoryTour";
import { harryPotterTour } from './harryPotterTour';
import { harryPotterTourAlternativeSecond } from "./harryPotterTourAlternativeSecond";
import { harryPotterTourAlternativeThird } from "./harryPotterTourAlternativeThird";
import { margaretIslandTour } from "./margareIslandTour";
import { pandemicTour } from "./pandemicTour";
import { presidentTour } from "./presidentTour";
import { PuzzleType, Tour } from "./types";

export const getPuzzleByTourAndId = (tourId, puzzleId) => {
  const language = new I18nService().getLanguage();
  return tours
    .find((tour) => tour.id === tourId)[language]
    .puzzles.find((puzzle) => puzzle.id === puzzleId);
};

export const getPuzzles = (tourId) => {
  const language = new I18nService().getLanguage();
  return tours.find((t) => t.id === tourId)[language].puzzles;
};

const languages = new I18nService().getPossibleLanguages().map((language) => language.language);

const tourHasLanguage = (tour, language): boolean => {
  return tour[language] !== undefined;
}

const tourHasEveryLanguage = (tour, languages): boolean => {
  return languages.filter((language) => tourHasLanguage(tour, language)).length === languages.length;
};

const tourHasAnyLanguage = (tour, languages) => {
  return languages.filter((language) => tourHasLanguage(tour, language)).length > 0;
}

const getFirstLanguageFromTour = (tour, languages) => {
  for (const language of languages) {
    if (tour[language] !== undefined){
      return language;
    }
  }
}

export const tours: Array<Tour> = [
  demoTour,
  presidentTour,
  margaretIslandTour,
  pandemicTour,
  freemansoryTour,
  ballOfPuskasTour,
  harryPotterTour,
  harryPotterTourAlternativeSecond,
  harryPotterTourAlternativeThird
].map((tour) => {
  // If tour misses any language.
  if (!tourHasEveryLanguage(tour, languages)){
    for (const language of languages) {
      if (!tourHasLanguage(tour, language)){
        // If tour don't have current language but has any.
      if (tourHasAnyLanguage(tour, languages)){
          const firstLanguage = getFirstLanguageFromTour(tour, languages);
          tour[language] = cloneDeep(tour[firstLanguage]);
          tour.id = tour[language].id;
        }
        else{
          // If tour don't have any language.
          tour[language] = cloneDeep(tour);
          tour.id = tour[language].id;
        }
      }
      tour[language] = cloneDeep(tour[language]);
    }
  }
  return tour;
}).map((tour) => {
  const output = {...tour};
  for (const language of languages) {
    output[language] = {
      ...output[language],
      puzzles: [
        {
          id: 0,
          type: PuzzleType.LANGUAGE_SELECTOR
        },
        ...tour[language].puzzles.map((puzzle, index) => {
          puzzle.id++;
          return puzzle;
        })
      ]
    }
  }
  return output;
});
