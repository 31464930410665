import React, { Fragment } from "react";
import Typography from "@material-ui/core/Typography";
import { useSelector } from "react-redux";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Box, Button } from "@material-ui/core";
import { PuzzleEnd } from "../../assets/tours/types";
import Fireworks from "fireworks-react";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { I18nService } from "../../services/i18n-service";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      height: "100vh"
    },
    header: {},
    content: {
      margin: theme.spacing(1),
      padding: theme.spacing(1),
      flexGrow: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start"
    },
    button: {},
    footer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: theme.spacing(2)
    }
  })
);

type EndPuzzleProps = {
  puzzle: PuzzleEnd;
  onEnd: Function;
};

const EndPuzzle: React.FC<EndPuzzleProps> = ({ puzzle, onEnd }) => {
  const classes = useStyles({});
  const { width } = useWindowDimensions();
  const session = useSelector(state => {
    console.log(JSON.stringify(state.apiReducer.session));
    return state.apiReducer.session;
  });

  const onSend = () => {
    onEnd();
  };

  return (
    <Fragment>
      <Box className={classes.content}>
        <Fireworks width={width - 32} height={200} />
        <Typography variant="h5" color="primary" component="h2">
          {puzzle.title}
        </Typography>
        <Typography variant="body1" color="textSecondary" component="p">
          {puzzle.description}
        </Typography>
        <Typography variant="h5" color="primary" component="h2">
          {new I18nService().getTranslationFor('result')}
        </Typography>
        <Typography variant="body1" color="textSecondary" component="p">
          {new I18nService().getTranslationFor('usedHelps')}: {session.hintCount}
        </Typography>
        <Typography variant="body1" color="textSecondary" component="p">
          {new I18nService().getTranslationFor('wrongTips')}: {session.missedCount}
        </Typography>
        <Typography variant="body1" color="textSecondary" component="p">
          {new I18nService().getTranslationFor('totalTime')}: {new Date(
            new Date(session.finishedAt).getTime() -
              new Date(session.startedAt).getTime()
          ).getMinutes()} {new I18nService().getTranslationFor('minutes')}
        </Typography>
      </Box>
      <Box className={classes.footer}>
        <Button
          size="large"
          variant="contained"
          className={classes.button}
          onClick={() => onSend()}
        >
          {new I18nService().getTranslationFor('homeScreen')}
        </Button>
      </Box>
    </Fragment>
  );
};

export default EndPuzzle;
