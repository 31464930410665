import { PuzzleType, Tour } from "./types";

export const margaretIslandTour: Tour = {
  id: 2,
  name: "A margitszigeti gyilkosság",
  description:
    "Fel kell derítenetek egy gyilkosságot és meg kell találnotok a bomba rejtekhelyét. Siessetek és mentsétek meg a várost. Csak rátok számíthatunk, mindenki a város evakuálásával van elfoglalva.",
  puzzles: [
    {
      id: 0,
      type: PuzzleType.START,
    },
    {
      id: 1,
      type: PuzzleType.STANDARD,
      shortTitle: "Bevezetés",
      title: `Segítség`,
      description: `Segítség
      A rendőrség nemrég egy fenyegető levelet kapott:
      
      "Mind meg fogtok halni! Felrobbantom egész Budapestet. Nem találtátok meg apám testét, se a gyilkosát.
      Emlékszem még csak kisfiú voltam, alig 10 éves és a 848 cm-es vízben kezdte el fojtogatni egy őrült.
      Persze, hogy nem tehettem mást és inkább elbújtam. Nem maradt más hátra, minthogy lesújtson a bosszúm és felrobbantsam a fél várost, beleértve a Margitszigetet is!
      Sosem fogtok megtalálni, így elárulhatom, hogy onnan fogom nézni a tűzijátékot, ahol a nap lemegy.
      
      Nehéz dolgotok lesz, fel kell derítenetek egy gyilkosságot és meg kell találnotok a bomba rejtekhelyét. Siessetek és mentsétek meg a várost. Csak rátok számíthatunk, mindenki a város evakuálásával van elfoglalva.
      
      Hol tartózkodhat jelenleg a gyilkos? Parlament, Margitsziget, Bazilika, Budai Vár, Hajógyári sziget, Városliget
            `,
      images: [
        {
          url: require("../../assets/images/margaretIsland/0.jpg"),
          copyright: "Légifotó: Civertan Grafikai Stúdió, www.legifoto.com",
        },
      ],
      solutionRegExp: /^(BUDAI VÁR|BUDAI VAR)$/,
      solutionKey: "BUDAI VÁR",
      hints: [
        {
          id: 0,
          text: "A szabadság szoborral jó barátok.",
          imgUrl: require("../../assets/images/margaretIsland/1.jpg"),
        },
        {
          id: 1,
          text:
            "Ha nem a Parlament, Margitsziget, Bazilika, Hajógyári sziget, Városliget, akkor mi lehet? ",
        },
        {
          id: 2,
          text: 'A megoldás "BUDAI VÁR"',
        },
      ],
    },
    {
      id: 2,
      type: PuzzleType.STANDARD,
      shortTitle: "Víz állás",
      title: `848`,
      description: `Ügyesek vagytok, egy osztagot már elküldtünk, hogy megkeressék a merénylőt.
            Menjetek a híd alá és keressétek meg, hogy mikor volt 848 cm a vízállás, hogy ki tudjuk deríteni, melyik margitszigeti gyilkosságról lehet szó.
            `,
      images: [require("../../assets/images/margaretIsland/848.jpg")],
      solutionRegExp: /^(2002)$/,
      solutionKey: "2002",
      hints: [
        {
          id: 0,
          text: "Keress két egymás mellett lévő táblát.",
        },
        {
          id: 1,
          text:
            "Álljatok háttal a szabadtéri kondi gépeknek. A híd talapzatának jobb oldalán lesznek a táblák.",
        },
        {
          id: 2,
          text: 'A megoldás "2002"',
        },
      ],
    },
    {
      id: 3,
      type: PuzzleType.STANDARD,
      shortTitle: "Gyilkosság",
      title: `Gyilkosság`,
      description: `Ebben az időszakban csak egy gyilkosság történt, az aktából viszont hiányzik a neve az áldozatnak, de a következőket írta a helyszínelő rendőr:

            „ Furcsa volt, mert ugye a gyilkosság nem messze történt attól a körforgalmi szobortól, amit ugyanolyan nevű személy készített, mint ahogy hívták az apukát és azt a kisfiút, akit reszketve találtunk meg.
            Ezt csak azért jegyeztem meg, mert tele volt mindenféle tárggyal az a szobor.”

            Menjetek a Margitsziget belseje felé, és keressétek a körforgalmat!
            `,
      images: [require("../../assets/images/margaretIsland/2.jpg")],
      solutionRegExp: /^(KISS ISTVÁN|KISS ISTVAN)$/,
      solutionKey: "KISS ISTVÁN",
      hints: [
        {
          id: 0,
          text:
            "Menjetek beljebb és keressétek meg a körforgalomnál a szobrot.",
        },
        {
          id: 1,
          text:
            "A szobor készítőjének neve egy kis szobor fatörzsére lesz írva.",
        },
        {
          id: 2,
          text: 'A megoldás "KISS ISTVÁN"',
        },
      ],
    },
    {
      id: 4,
      type: PuzzleType.STANDARD,
      shortTitle: "Akta",
      title: `Akta`,
      description: `
            Megvan a robbantónk neve, amíg megkeresik a Várban, olvassátok tovább az aktát.

            "szóval ott álldogáltunk ennél a szobornál, amikor megjelent egy férfi, aki teljesen le volt sápadva, hogy megfenyegették.„
            A lap többi része már csak nagyon halványan kivehető, mintha próbálták volna eltüntetni vagy eltusolni a dolgot.

            „Azt mndt hgy ő a hungária evzo egysulet elnke és hogy mst nem tud bszlni, de ő tdja ki volt a gylks és hogy keressük mg az egyslet házát és az emléktábla évszmát mondjuk el neki kódként, akkor tdni fgja hogy mi vagynk és elmndja amit tud. Mrdjatok mindg a jobb oldalon akkr meglesz a ház. Nm vettuk kmlyan. Mndtam is a kollégnk hgy ez csak egy blond.

            Azt már tudjuk, hogy a férfi a csobogó szökőkút felöl jött, de elképzelhető hogy sokat kell majd sétálni.
            Mindig maradjatok a jobb oldalon, menjetek a szökőkút irányába, de ne álljatok meg, sokat kell sétálnotok.

            Ne feledjétek elmondani a kódót!
            `,
      images: [require("../../assets/images/margaretIsland/3.jpg")],
      solutionRegExp: /^(1989)$/,
      solutionKey: "1989",
      hints: [
        {
          id: 0,
          text: "Hungária Evező Egyesület",
        },
        {
          id: 1,
          text: "",
          imgUrl: require("../../assets/images/margaretIsland/4.jpg"),
        },
        {
          id: 2,
          text: 'A megoldás "1989"',
        },
      ],
    },
    {
      id: 5,
      type: PuzzleType.STANDARD,
      shortTitle: "Egyesületi ház",
      title: `Válaszoljatok a kérdésekre`,
      description: `Megtalálták a férfit, aki ebben az időben volt az egyesület elnöke. Még mindig retteg, és nem hiszi, hogy valaki akkor akar a gyilkosság után nyomozgatni a Margitszigeten, miközben evakuálják az egész várost. Így most egy pár kérdést fel fog nektek tenni, amiből megbizonyosodhat, hogy ti a helyszínen vagytok.

            Keressétek meg az állatkertet és válaszoljatok a következő kérdésekre. (A vadaskert a Rózsakert közelében található)

            1) Pusztai sas, Réti sas
            2) Maláj sas, Koronás Sas
            3) Haast féle sas, Karvaly
            4) Parlagi sas, Vörös kánya
            `,
      images: [require("../../assets/images/margaretIsland/madar2.jpg")],
      solutionRegExp: /^(1)$/,
      solutionKey: "1",
      hints: [
        {
          id: 0,
          text:
            "Az állatkert a jobb oldalon lesz, ha megvan, nézzétek meg a táblákat és válasszátok ki azt a párosítást, amelyik valóban van az állatkertben",
        },
        {
          id: 1,
          text: "A sasok egymás mellett vannak.",
        },
        {
          id: 2,
          text: 'A megoldás "1"',
        },
      ],
    },
    {
      id: 6,
      type: PuzzleType.STANDARD,
      shortTitle: "Gyilkosság",
      title: `Válaszoljatok a kérdésekre`,
      description: `Mi a közös mind a két sasban?

            1) Ugyanolyan a tolluk színe
            2) Mindkettő kevésbé fenyegetett
            3) Elterjedése: Eurázsia
            4) Mindkettő 7-8 tojással szaporodik
            `,
      images: [require("../../assets/images/margaretIsland/madar2.jpg")],
      solutionRegExp: /^(3)$/,
      solutionKey: "3",
      hints: [
        {
          id: 0,
          text: "Olvassátok el a táblán lévő információkat.",
        },
        {
          id: 1,
          text: "Az elterjedése nekünk a fontos",
        },
        {
          id: 2,
          text: 'A megoldás "3"',
        },
      ],
    },
    {
      id: 7,
      type: PuzzleType.STANDARD,
      shortTitle: "Válaszoljatok a kérdésekre",
      title: `Válaszoljatok a kérdésekre`,
      description: `Eddig jók vagytok, most jön az utolsó kérdés.
            Melyik a kakukktojás?

            1) Bahama réce
            2) Szerpentin réce
            3) Mandarin réce.
            4) Kisasszony réce
            `,
      images: [require("../../assets/images/margaretIsland/madar2.jpg")],
      solutionRegExp: /^(2)$/,
      solutionKey: "2",
      hints: [
        {
          id: 0,
          text: "Keressétek a réce táblákat",
        },
        {
          id: 1,
          text: "Az egyik réce nem létezik",
        },
        {
          id: 2,
          text: 'A megoldás "2"',
        },
      ],
    },
    {
      id: 8,
      type: PuzzleType.STANDARD,
      shortTitle: "Gyilkosság",
      title: `Szent sír`,
      description: `Az egyesület elnöke most már kezd megbízni bennetek, de még mindig nem meri elárulni hol található a gyilkos. Előbb meg kell találnotok, hogy hova rejtette a holttestet, ezért a következőket mondja:
            „Menjetek végig a Rózsakerten és ahol négy út keresztezi egymást, ott lesz Táncsics mellszobra.
            Ő pont arrafelé tekint, ahol a gyilkos felnyitotta egy Szent sírját és belerejtette az áldozatot.
            Ha a Szent sírja megvan, írjátok le a születési évszámát.
            `,
      images: [require("../../assets/images/margaretIsland/5.jpg")],
      solutionRegExp: /^(1242)$/,
      solutionKey: "1242",
      hints: [
        {
          id: 0,
          text: "Keressétek a Domonkos kolostor sírjait",
        },
        {
          id: 1,
          text: "Keressétek Szent Margit sírját",
          imgUrl: require("../../assets/images/margaretIsland/6.jpg"),
        },
        {
          id: 2,
          text: 'A megoldás "1242"',
        },
      ],
    },
    {
      id: 9,
      type: PuzzleType.STANDARD,
      shortTitle: "Gyilkosság",
      title: `Hol a bomba?`,
      description: `Megtalálták a Robbantót a rendőrök, de sehogy sem tudják szóra bírni. Találjátok meg minél előbb az apjának a gyilkosát, hogy elárulja, hova rejtette a bombát. Az egyesület volt elnöke a következőket mondta:

            „Nem emlékszem már pontosan hol volt ez az épület, valahogy úgy jegyeztem meg, hogy Beck Ö Fülöp ránézett egy férfi mellszoborra, aztán az a mellszobor tekintett egy teljes alakos nőre. Az a nő pedig egy távolban lévő zenész mellszobrára tekintett, ő viszont elfordította a tekintetét. Na, az ő közelében van, az a hely ahol az a férfi lakik és imádkozik is. „

            Keressétek a kolostor közelében Beck Ö Fülöp mellszobrát.
            Mondjátok meg, mi a hely neve és akkor meglesz a gyilkos.
            `,
      images: [require("../../assets/images/margaretIsland/7.jpg")],
      solutionRegExp: /^(SZENT MIHALY KAPOLNA|SZENT MIHÁLY KÁPOLNA|SZENT MIHALY KÁPOLNA|SZENT MIHÁLY KAPOLNA)$/,
      solutionKey: "SZENT MIHÁLY KÁPOLNA",
      hints: [
        {
          id: 0,
          text: "Kápolnát keressetek",
        },
        {
          id: 1,
          text: "A Bartók Béla mellszobor közelében van a kápolna",
        },
        {
          id: 2,
          text: 'A megoldás "SZENT MIHÁLY KÁPOLNA"',
        },
      ],
    },
    {
      id: 10,
      type: PuzzleType.STANDARD,
      shortTitle: "Gyilkosság",
      title: `Imádkozz`,
      description: `Megtaláltátok a gyilkos búvóhelyét! Hamarosan küldünk érte egy elfogó csapatot.
            A robbantó teljesen összetört, amikor meghallotta a hírt, hol bujkál a gyilkos, hiszen minden nap odajárt édesapja lelkéért imádkozni. Azt mondja, sietve térdeljetek le a bejárat előtt, nézzetek fel Isten felé és jegyezzetek meg mindent, amit láttok. Később szükség lesz a látottakra.
            Fótozzátok le, úgy könnyebb lesz megjegyezni, vagy írjátok le.

            Már csak 14 perc van a robbanásig!

            Ahhoz, hogy a bombához be tudjátok írni a kódot, rá kell kapcsolódni. Két jelszó védi. Menjetek, egyenesen a Japánkerthez ott van a hotel mellett és keressétek meg a lányt a tavon.
            Első kód kérdése: Mit csinál a lány a tavon?
            `,
      images: [require("../../assets/images/margaretIsland/8.jpg")],
      solutionRegExp: /^(ÜL|UL)$/,
      solutionKey: "ÜL",
      hints: [
        {
          id: 0,
          text: "Nem áll, hanem?",
        },
        {
          id: 1,
          text: "",
          imgUrl: require("../../assets/images/margaretIsland/9.jpg"),
        },
        {
          id: 2,
          text: 'A megoldás "ÜL"',
        },
      ],
    },
    {
      id: 11,
      type: PuzzleType.STANDARD,
      shortTitle: "Gyilkosság",
      title: `Rákász fiú`,
      description: `Az első kóddal meg is vagyunk. Most keressétek meg a rákász fiút.

            Miből van a rákászfiú?
            `,
      images: [require("../../assets/images/margaretIsland/10.jpg")],
      solutionRegExp: /^(BRONZ)$/,
      solutionKey: "BRONZ",
      hints: [
        {
          id: 0,
          text: "Az időszámításunkban hívtak így egy bizonyos kort is.",
        },
        {
          id: 1,
          text: "A réz különféle ötvözeteit nevezik így.",
        },
        {
          id: 2,
          text: 'A megoldás "BRONZ"',
        },
      ],
    },
    {
      id: 12,
      type: PuzzleType.STANDARD,
      shortTitle: "Gyilkosság",
      title: `Hatástalanítás`,
      description: `Megvan a másik kód is, rákapcsolódtunk, de nem vagytok elég közel a bombához.
            Keressétek meg a nagy zenélő dobozt, álljatok közvetlenül a kupola alá és írjátok be a templom kódját, hogy megálljon a bomba visszaszámlálása.

            A templom kódja az a 9 karakter, ahol több a könyörgés.
            `,
      images: [require("../../assets/images/margaretIsland/11.jpg")],
      solutionRegExp: /^(\+16NOSCE\+)$/,
      solutionKey: "+16NOSCE+",
      hints: [
        {
          id: 0,
          text: "A templom bejárata felett található volt a kód",
        },
        {
          id: 1,
          text: "Az angyal bal oldalán volt olvasható",
        },
        {
          id: 2,
          text: 'A megoldás "+16NOSCE+"',
        },
      ],
    },
    {
      id: 13,
      type: PuzzleType.END,
      shortTitle: "Vége",
      title: "Gratulálunk!",
      description: `Gratulálunk! Megmentettétek a várost, a kupolából hamarosan kiveszik a most már deaktivált bombát.
            A gyilkost ebben a pillanatban elkapták, a bombakészítőnek hiába a szomorú történet, végül börtönbe fog kerülni.

            Ti viszont, igazi nyomozók vagytok! Most már tudjuk, hogy rátok mindig számíthatunk.
            Ha még maradt energiátok, menjetek és igyatok valami finomságot, mert megérdemlitek :)
            `,
      url: "https://idejöhetatöbbijátékurlje.com", // TODO: get url from Miklos
    },
  ],
};
