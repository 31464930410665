export const API_SESSION_FETCH = "API_SESSION_FETCH";
export const API_SESSION_ERROR = "API_SESSION_ERROR";
export const API_SESSION_INVALID = "API_SESSION_INVALID";
export const API_SESSION_NOCODE = "API_SESSION_NOCODE";
export const API_SESSION_SUCCESS = "API_SESSION_SUCCESS";

export const API_SOLVE_PUZZLE = "API_SOLVE_PUZZLE";
export const API_SOLVE_PUZZLE_SUCCESS = "API_SOLVE_PUZZLE_SUCCESS";
export const API_SOLVE_PUZZLE_ERROR = "API_SOLVE_PUZZLE_ERROR";

export const API_LOG_ANSWER = "API_LOG_ANSWER";

export const API_EXECUTE_MUTATION = "API_EXECUTE_MUTATION";
export const API_EXECUTE_MUTATION_SUCCESS = "API_EXECUTE_MUTATION_SUCCESS";

export const API_LOG_OUT = "API_LOG_OUT";
