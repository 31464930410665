import { PuzzleType, TranslatedTour } from "./types";

export const pandemicTour: TranslatedTour = {
  id: 3,
  hu: {
    id: 3,
    name: "Idegméreg a levegőben",
    description:
      "Készüljetek fel a legrosszabbra, egy őrült orosz tudós feltalált egy új idegmérget, amely gyengíti a szabad akaratot.",
    puzzles: [
      {
        id: 0,
        type: PuzzleType.START,
      },
      {
        id: 1,
        type: PuzzleType.STANDARD,
        shortTitle: "Bevezetés",
        title: `Idegméreg a levegőben`,
        description: `„A játék cselekménye kitalált történeten alapul, bárminemű egyezése a valósággal, csupán a véletlen műve.”
        
        Kedves nyomozók, örülök, hogy megérkeztetek. Egy percet sem veszíthetünk. Én innen az Alkotmányvédelmi Hivatal titkos bázisából foglak titeket segíteni. Készüljetek fel a legrosszabbra, egy őrült orosz tudós feltalált egy új idegmérget, amely gyengíti a szabad akaratot. Így akarja elérni, hogy a magyarok minden politikai témában támogassák, illetve ne akarjanak új energiatermelő eszközöket létrehozni, inkább az orosz olajat és gázt használják. Ha jól körülnéztek, láthatjátok, hogy körülöttetek az emberek már kezdenek furcsán viselkedni, ugyanis az idegméreg a levegőbe került. Ha egy kicsit koncentráltok, észrevehetitek, hogy egy-két ember furcsán jár, összefüggéstelenül beszél, vagy kicsit remeg a keze. Veszély esetére, ha esetleg továbbterjedne a méreg, vagy ha esetleg rá is hatással lenne, az ellenszer képletét eldugta több helyre is. Már csak meg kellene találni azt a helyet, ahova elrejthette a nyomokat.
        
        A legjobb nyomozóink dolgoztak az ügyön, de csak egy galambpostát sikerült elkapni a tudóstól. Nikolaj Szelindi ahogy meglátta a rendőröket, azonnal menekülőre fogta. Amíg elkapják, el a nyomokon! induljatok Készen álltok?
        
        (IGEN/NEM)`,
        images: [require("../../assets/images/pandemic/0.jpeg")],
        solutionRegExp: /^(IGEN)$/,
        solutionKey: "IGEN",
        hints: [
          {
            id: 0,
            text: "Igen vagy nem?",
          },
          {
            id: 1,
            text: "Ha készen álltok írjátok be, hogy IGEN",
          },
          {
            id: 2,
            text: 'A megoldás "IGEN"',
          },
        ],
      },
      {
        id: 2,
        type: PuzzleType.STANDARD,
        shortTitle: "Arany",
        title: `Arany`,
        description: `Miközben a Nyikolaj Szelindi a rendőrök elől futott, rengeteg cetlit elhagyott, amelyeken kérdések voltak. Próbáljátok őket megfejteni, hogy eljussunk az első helyre, ahol feltehetően az ellenszer képletének egy részét találjátok. Nem minden Arany, ami fénylik, de aki leborul lába elé, megtalálja a megfejtést. Milyen madár látható?`,
        images: [require("../../assets/images/pandemic/1.jpg")],
        solutionRegExp: /^(TURUL)$/,
        solutionKey: "TURUL",
        hints: [
          {
            id: 0,
            text: "A leghíresebb magyar.",
          },
          {
            id: 1,
            text: "Jelképünk sok helyen.",
          },
          {
            id: 2,
            text: 'A megoldás "TURUL".',
          },
        ],
      },
      {
        id: 3,
        type: PuzzleType.STANDARD,
        shortTitle: "Kőbe zárt férfi",
        title: `Kőbe zárt férfi`,
        description: `Keressétek Herman Ottót a kőbe zárt férfit!
        A kőbe zárt férfi vigyáz a két pindurkára, anyjuk a távolból figyel.
        Ezeknek az állatoknak az összességének nevét keressük…. már el is hangzott.
              `,
        images: [require("../../assets/images/pandemic/2.jpg")],
        solutionRegExp: /^(MADARAK)$/,
        solutionKey: "MADARAK",
        hints: [
          {
            id: 0,
            text: "Keressétek meg Herman Ottó szobrát és figyeljétek meg, milyen állatok vannak a környékén!",
          },
          {
            id: 1,
            text: "Tollas állatok.",
          },
          {
            id: 2,
            text: 'A megoldás "MADARAK"',
          },
        ],
      },
      {
        id: 4,
        type: PuzzleType.STANDARD,
        shortTitle: "Madarak anyja",
        title: `Madarak anyja`,
        description: `
        Madarak anyja alatti szöveg és a számozás adja meg a helyet, amit keresünk. 
        A szóközt ne vedd figyelembe, ha egy betű kétjegyű, akkor a betűkkel külön foglalkozz.
  
        3/1
        3/11
        3/15
        3/14
        1/6
        1/5
        1/2
        8/16
        8/18
        8/7
        5/16
        1/9
        1/7
              `,
        images: [require("../../assets/images/pandemic/3.jpg")],
        solutionRegExp: /^(KÁROLYI PALOTA|KAROLYI PALOTA)$/,
        solutionKey: "KÁROLYI PALOTA",
        hints: [
          {
            id: 0,
            text: "Az első szám a sort jelöli, a második a betűt.",
          },
          {
            id: 1,
            text: "Egy palota nevének kell kijönnie.",
          },
          {
            id: 2,
            text: 'A megoldás "KÁROLYI PALOTA"',
          },
        ],
      },
      {
        id: 5,
        type: PuzzleType.STANDARD,
        shortTitle: "Elhagyatott palota",
        title: `Elhagyatott palota`,
        description: `Megtalálták a Szelindi kitépett naplójának részleteit a Palotában. A Károlyi Palota a Pollack Mihály tér 10. alatt található, tőletek kb 150 méterre. Rímben írta le a megoldó kulcsokat.
        
        Nagyon ügyesek vagytok, megtaláltátok az elhagyatott Károlyi-palotát. Érdekes, mert innen több feljelentés is érkezett az elmúlt időszakban, hogy folyamatosan madarak röpködnek körülötte hajnalban.
        
        Már nem vagytok messze a megoldástól:
        
        Kicsi oroszlán a mi fejünk, ablakok felett rád tekintünk. Ha a számunk pont ötvenegy, Mikszáthot kell keresned, ha kevesebb, úgy Arany lesz az ezüstöd és vele kell majd törődnöd, ha nem kevesebb, hanem több, Semmelweis felé kell pördülnöd.
        Írd be megoldásnak hogy Arany, Mikszáth, Semmelweis tehát amire gondoltok.`,
        images: [require("../../assets/images/pandemic/4.jpg")],
        solutionRegExp: /^(MIKSZÁTH|MIKSZATH|MIKSZATHOT|MIKSZÁTHOT)$/,
        solutionKey: "MIKSZÁTH",
        hints: [
          {
            id: 0,
            text: "Számold meg az oroszlán fejeket",
          },
          {
            id: 1,
            text: "51 darab van",
          },
          {
            id: 2,
            text: 'A megoldás "MIKSZÁTH"',
          },
        ],
      },
      {
        id: 6,
        type: PuzzleType.STANDARD,
        shortTitle: "Hova tekint",
        title: `Ó Uram, hova tekint?`,
        description: `Most pedig keressétek meg a Mikszáth Téren a tér urának tekintetét. Ha nem tudjátok pontosan hova néz, ne feledjétek: „Sok gond van a madarakkal mostanában”.
        
        Mi a hely neve?`,
        images: [require("../../assets/images/pandemic/5b.jpg")],
        solutionRegExp: /^(KIS FECSKE)$/,
        solutionKey: "KIS FECSKE",
        hints: [
          {
            id: 0,
            text: "Fészküket gyakran leverik a házak falairó",
          },
          {
            id: 1,
            text: "Állítólag nem csinál egyedül nyarat",
          },
          {
            id: 2,
            text: 'A megoldás "KIS FECSKE"',
          },
        ],
      },
      {
        id: 7,
        type: PuzzleType.STANDARD,
        shortTitle: "Beszéljetek a pultossal",
        title: `Beszéljetek a pultossal`,
        description: `
        Sikerült feltörnünk a Kis Fecske hely nevével egy jelszót a doktor cetlijeiből. 
        Mondjátok a Pultosnak a következőt: „A bödön zsíros köcsögömmel hova mégyen?”
  
        Beszéltetek a pultossal? (IGEN/NEM)
        Csak akkor nyomjatok Igent, ha megkaptátok tőle a cetlit aztán lesz 10 perc szünetetek, hogy tudjatok pihenni.
        
        A Cetli nagyon fontos. Vigyázzatok rá és kérjétek el!! !
        `,
        images: [require("../../assets/images/pandemic/kis-fecske.jpg")],
        solutionRegExp: /^(IGEN)$/,
        solutionKey: "IGEN",
        hints: [
          {
            id: 0,
            text: 'A megoldás "IGEN"',
          },
        ],
      },
      {
        id: 8,
        type: PuzzleType.COUNTER,
        shortTitle: "Pihenő",
        title: "Pihenő",
        description: `Kaptok 10 perc szünetet, amíg tudtok gondolkozni és egy kicsit pihenni.
        
        Mondtátok a jelszót a pultosnak: „A bödön zsíros köcsögömmel hova mégyen?” cserébe pedig kaptatok egy cetlit. Nagyon vigyázzatok a cetlire.
        Természetesen tudtok tovább maradni 10 percnél, ha szeretnétek.`,
        images: [require("../../assets/images/president/stopper.jpg")],
        amount: 600,
      },
      {
        id: 9,
        type: PuzzleType.STANDARD,
        shortTitle: "Vizes igazság",
        title: `Vizes igazság`,
        description: `Keresd a Szabó Ervin Könyvtár közelében az igazság évszámát. Lehet, hogy kicsit vizes.
        `,
        images: [require("../../assets/images/pandemic/6.jpg")],
        solutionRegExp: /^(1928)$/,
        solutionKey: "1928",
        hints: [
          {
            id: 0,
            text: "Egy szökőkúton találod a feliratot.",
          },
          {
            id: 1,
            text: "„Az Ő betűje megöli a hatalmaskodást, az ő lelke megeleveníti az igazságot.„",
          },
          {
            id: 2,
            text: 'A megoldás "1928"',
          },
        ],
      },
      {
        id: 10,
        type: PuzzleType.STANDARD,
        shortTitle: "Csillagok felett",
        title: `Csillagok felett`,
        description: `Keresd a Kálvin tér legmagasabb csillagát. Ha az igazság kútjának idejéből kivonsz 98-at, akkor megkapod, hogy mikor fejezték be az épületet, amit keresel.
              `,
        images: [require("../../assets/images/pandemic/7.jpg")],
        solutionRegExp: /^(1830)$/,
        solutionKey: "1830",
        hints: [
          {
            id: 0,
            text: "A Kávin téren található ez az épület.",
          },
          {
            id: 1,
            text: "Kálvin János áll vele szemben.",
          },
          {
            id: 2,
            text: 'A megoldás "1830"',
          },
        ],
      },
      {
        id: 11,
        type: PuzzleType.STANDARD,
        shortTitle: "Ki a költő?",
        title: `Ki a költő?`,
        description: `Menjetek a Kálvin tér legmagasabb csillagához. Ez egy épület, ami 1830-ban épült.
  
        Ha 11:55-öt mutat az óra, akkor megtalálod egy költő nevét, akire szükségünk lesz a következő rejtvényhez.
        `,
        images: [require("../../assets/images/pandemic/7b.jpg")],
        solutionRegExp: /^(ADY ENDRE)$/,
        solutionKey: "ADY ENDRE",
        hints: [
          {
            id: 0,
            text: "Ha a templommal szemben állsz, akkor arrafele indulj, amerre a mutató 11:55 felé áll.",
          },
          {
            id: 1,
            text: "A földre nézz és megtalálod a költőt.",
          },
          {
            id: 2,
            text: 'A megoldás "ADY ENDRE"',
          },
        ],
      },
      {
        id: 12,
        type: PuzzleType.STANDARD,
        shortTitle: "Mit keresel?",
        title: `Mit keresel?`,
        description: `ADY ENDRE verse és a számok alapján rakd össze, hogy melyik színházba kell mennetek.
        Csak a betűkkel foglalkozz! Szóköz, perjel, vessző, pont kötőjel, nem számít. Kettős betűk egynek számítanak.
  
        A számok a következők: 1,21,80,14,7, 16,2,7`,
        images: [require("../../assets/images/pandemic/7c.jpg")],
        solutionRegExp: /^(PUDER BAR|PÚDER BÁR|PUDER BÁR|PÚDER BAR)$/,
        solutionKey: "PUDER BAR",
        hints: [
          {
            id: 0,
            text: "Mindegyik szám a betűt jelöli",
          },
          {
            id: 1,
            text: "Ady verséből kell kiszámolni",
          },
          {
            id: 2,
            text: 'A megoldás "PÚDER BÁR"',
          },
        ],
      },
      {
        id: 13,
        type: PuzzleType.STANDARD,
        shortTitle: "Mi a jelszó?",
        title: `Mi a jelszó?`,
        description: `Menjetek a Púder Bárszínházba (Ráday utca 8. 1092) Nyikolajt szerencsére elkapták, de nem volt nála semmilyen ellenszer. Viszont egyre kevesebb dologra emlékszik. Egy naplót találtak nála a rendőrök, ami tele van mindenféle kóddal. Megpróbálják feltörni, addig menjetek és igyatok valamit az új helyen. Vigyázzatok, ha rákérdeznének, hogy kik vagytok, hazudjatok valamit! Próbáljatok nem túl feltűnőek lenni. Mindenképpen szerezzétek meg a cetliket. A második és egyben utolsó helyen vagytok, szóval most már nyugodtan ehettek és ihattok. Emlékeztek még a jelszóra, amit az előző helyen kaptatok? Mondjátok a pultosnak, és írjátok ide a megoldásnak, miután megkaptátok a cetlit a felszolgálótól!`,
        images: [require("../../assets/images/pandemic/jelszo.jpg")],
        solutionRegExp: /^(ELVESZTETTEM A KECSKÉMET|ELVESZTETTEM A KECSKEMET|elvesztettem a kecskémet|elvesztettem a kecskemet|Elvesztettem a kecskémet|Elvesztettem a kecskemet|ELVESZTETTEM A KECSKÈMET|ELVESZTETTEM A KECSKEMET|elvesztettem a kecskèmet|elvesztettem a kecskemet|Elvesztettem a kecskèmet|Elvesztettem a kecskemet)$/,
        solutionKey: "ELVESZTETTEM A KECSKÉMET",
        hints: [
          {
            id: 0,
            text: 'A megoldás "ELVESZTETTEM A KECSKÉMET"',
          },
        ],
      },
      {
        id: 14,
        type: PuzzleType.COUNTER,
        shortTitle: "Púder bárszínház",
        title: "Púder bárszínház",
        description: `A cetlit mindenképpen kérjétek el, anélkül ne menjetek tovább és nagyon vigyázzatok rá! Ne feledjétek, minden mozdulatokat figyelik!`,
        images: [require("../../assets/images/pandemic/puder.jpg")],
        amount: 600, // In sec
      },
      {
        id: 15,
        type: PuzzleType.STANDARD,
        shortTitle: "Végső megoldás",
        title: `Végső megoldás`,
        description: `A Doktor munkatársait elkapták, és rögtön elárulták, hogy a képlet utolsó darabját egy poháron találjátok a Púderben, ami megmenthet mindenkit a fenyegető veszélytől! Tehát, ha még nem rendeltetek, akkor várjátok meg az italokat. Van esetleg tippetek, hogy mi lehet a teljes megoldás? Nézzetek körül! Ha nem találtátok meg az írást, tegyétek szóvá a pincérnél! Megvan mind a három nyom a képlethez? (IGEN/NEM)`,
        images: [
          require('../../assets/images/pandemic/final.jpg')
        ],
        solutionRegExp: /^(IGEN)$/,
        solutionKey: "IGEN",
        hints: [
          {
            id: 0,
            text: 'A megoldás "IGEN"',
          },
        ],
      },
      {
        id: 16,
        type: PuzzleType.STANDARD,
        shortTitle: "A képlet",
        title: `A képlet`,
        description: `Rakjátok sorrendbe az első, a második és most a pohárról kapott képlet részleteit! írjátok be megoldásnak! Rögtön igyatok is egyet, hogy kiöljétek magatokból a vírust!`,
        images: [require("../../assets/images/pandemic/15.jpg")],
        solutionRegExp: /^(H2O\+ALKOHOL)$/,
        solutionKey: "H2O+ALKOHOL",
        hints: [
          {
            id: 0,
            text: "Azokat a nyomokat rakjátok sorba, amiket a cetliken kaptatok!",
          },
          {
            id: 1,
            text: "Egy képletnek kell kijönnie!",
          },
          {
            id: 2,
            text: 'A megoldás "H2O+ALKOHOL"',
          },
        ],
      },
      {
        id: 17,
        type: PuzzleType.END,
        shortTitle: "Vége",
        title: "Gratulálunk!",
        description: `Ügyesek voltatok! Megtaláltátok az ellenszert, és egyúttal lebuktattátok magatokat. Ti is Nyikolaj tudósai vagytok, csak az idegméreg hatása miatt nem emlékeztetek. Azonnal elkészítjük az ellenszérumot, és elkezdjük szétpermetezni egy helikopter segítségével. Mivel megkedveltelek titeket, még kaptok két órát, hogy igyatok, utána küldjük értetek a rendőröket. Köszönjük, és reméljük minél hamarabb kijöhettek a börtönből, hogy újra játszhassunk! Most igyatok, egyetek és érezzétek jól magatokat!`,
        url: "https://idejöhetatöbbijátékurlje.com",
      },
    ],
  },
  en: {
    id: 3,
    name: "Nerve Agent in the Air",
    description:
      "Készüljetek fel a legrosszabbra, egy őrült orosz tudós feltalált egy új idegmérget, amely gyengíti a szabad akaratot.",
    puzzles: [
      {
        id: 0,
        type: PuzzleType.START,
      },
      {
        id: 1,
        type: PuzzleType.STANDARD,
        shortTitle: "Introduction",
        title: `Nerve Agent in the Air`,
        description: `“The plot of the game is based on a fictional story; any resemblance to reality is purely coincidental.”
        
        Dear investigators, I'm glad you've arrived. We can't waste a single minute. I'll be assisting you from the secret base of the Constitutional Protection Office. Prepare for the worst: a mad Russian scientist has invented a new nerve agent that weakens free will. His goal is to ensure that Hungarians support him in all political matters and avoid developing new energy-producing tools, favoring Russian oil and gas instead. If you look around, you’ll notice that people are already starting to act strangely, as the nerve agent has been released into the air. If you concentrate, you may notice that some people walk oddly, speak incoherently, or have a slight tremor in their hands. In case of danger, or if the poison spreads further and affects you as well, the antidote's formula has been hidden in several locations. You just need to find the places where the clues have been hidden.
        
        Our best detectives have worked on the case, but so far, we've only managed to intercept a pigeon mail from the scientist. As soon as Nikolai Szelindi spotted the police, he made a run for it. Until they catch him, follow the clues!
        
        Are you ready? (YES/NO) `,
        images: [require("../../assets/images/pandemic/0.jpeg")],
        solutionRegExp: /^(YES)$/,
        solutionKey: "YES",
        hints: [
          {
            id: 0,
            text: "Yes or no?",
          },
          {
            id: 1,
            text: "If you're ready, type YES",
          },
          {
            id: 2,
            text: 'The solution is "YES"',
          },
        ],
      },
      {
        id: 2,
        type: PuzzleType.STANDARD,
        shortTitle: "Gold",
        title: `Gold`,
        description: `As Nikolai Szelindi ran from the police, he dropped several notes with questions on them. Try to solve them to reach the first location where you’ll likely find part of the antidote's formula.
        Not everything that glitters is gold, but whoever bows before it will find the answer.
        What bird can be seen?`,
        images: [require("../../assets/images/pandemic/1.jpg")],
        solutionRegExp: /^(TURUL)$/,
        solutionKey: "TURUL",
        hints: [
          {
            id: 0,
            text: "The most famous Hungarian.",
          },
          {
            id: 1,
            text: "Our symbol in many places.",
          },
          {
            id: 2,
            text: 'The solution is "TURUL".',
          },
        ],
      },
      {
        id: 3,
        type: PuzzleType.STANDARD,
        shortTitle: "Man in Stone",
        title: `Man in Stone`,
        description: `Search for Herman Ottó, the man in stone!
        The man in stone watches over two little ones, while their mother watches from afar.
        We are looking for the collective name of these animals... it has already been mentioned.`,
        images: [require("../../assets/images/pandemic/2.jpg")],
        solutionRegExp: /^(BIRDS)$/,
        solutionKey: "BIRDS",
        hints: [
          {
            id: 0,
            text: "Find the statue of Herman Ottó and observe what animals are around it!",
          },
          {
            id: 1,
            text: "Feathered animals.",
          },
          {
            id: 2,
            text: 'The solution is "BIRDS"',
          },
        ],
      },
      {
        id: 4,
        type: PuzzleType.STANDARD,
        shortTitle: "Mother of Birds",
        title: `Mother of Birds`,
        description: `
        The text under the Mother of Birds and the numbering will give you the location we're looking for.
        Ignore spaces, and if a letter is two characters, deal with the letters separately.
        3/1
        3/11
        3/15
        3/14
        1/6
        1/5
        1/2
        8/16
        8/18
        8/7
        5/16
        1/9
        1/7`,
        images: [require("../../assets/images/pandemic/3.jpg")],
        solutionRegExp: /^(KÁROLYI PALOTA|KAROLYI PALOTA)$/,
        solutionKey: "KÁROLYI PALOTA",
        hints: [
          {
            id: 0,
            text: "The first number indicates the line, the second the letter.",
          },
          {
            id: 1,
            text: "It should form the name of a palace.",
          },
          {
            id: 2,
            text: 'The solution is "KÁROLYI PALOTA"',
          },
        ],
      },
      {
        id: 5,
        type: PuzzleType.STANDARD,
        shortTitle: "Abandoned Palace",
        title: `Abandoned Palace`,
        description: `You've found parts of Szelindi's torn journal in the palace. The Károlyi Palace is located at Pollack Mihály Square 10, about 150 meters from you. He wrote down the keys to the solution in rhyme.
        
        Great job, you've found the abandoned Károlyi Palace. Interestingly, several reports have come in recently that birds have been circling it at dawn. You are not far from the solution:
        
        A small lion is our head, we look at you from above the windows. If our number is exactly fifty-one, you need to look for Mikszáth; if it's less, then Arany will be your silver, and you’ll need to deal with him. If not less, but more, turn towards Semmelweis.
        Enter the answer as Arany, Mikszáth, Semmelweis—whichever you think.`,
        images: [require("../../assets/images/pandemic/4.jpg")],
        solutionRegExp: /^(MIKSZÁTH|MIKSZATH|MIKSZATHOT|MIKSZÁTHOT)$/,
        solutionKey: "MIKSZÁTH",
        hints: [
          {
            id: 0,
            text: "Count the lion heads.",
          },
          {
            id: 1,
            text: "There are 51 of them.",
          },
          {
            id: 2,
            text: 'The solution is "MIKSZÁTH"',
          },
        ],
      },
      {
        id: 6,
        type: PuzzleType.STANDARD,
        shortTitle: "Where Does He Look?",
        title: `Oh Lord, Where Does He Look?`,
        description: `Now find the gaze of the lord of Mikszáth Square. If you're not sure where he looks, remember: "There are many problems with the birds these days."
        
        What is the name of the place?`,
        images: [require("../../assets/images/pandemic/5b.jpg")],
        solutionRegExp: /^(KIS FECSKE)$/,
        solutionKey: "KIS FECSKE",
        hints: [
          {
            id: 0,
            text: "Their nests are often knocked down from house walls.",
          },
          {
            id: 1,
            text: "Apparently, one doesn’t make a summer.",
          },
          {
            id: 2,
            text: 'The solution is "KIS FECSKE"',
          },
        ],
      },
      {
        id: 7,
        type: PuzzleType.STANDARD,
        shortTitle: "Talk to the Bartender",
        title: `Talk to the Bartender`,
        description: `We managed to crack a password from the doctor's notes with the name of Little Swallow.
        
        Tell the Bartender the following: "Where are you going with my jar of greasy jug?"
        Did you talk to the bartender? (YES/NO)
        Only press Yes if you've received the note from him. Then you'll have a 10-minute break to rest.
        
        The note is very important. Take care of it and make sure to ask for it!`,
        images: [require("../../assets/images/pandemic/kis-fecske.jpg")],
        solutionRegExp: /^(YES)$/,
        solutionKey: "YES",
        hints: [
          {
            id: 0,
            text: 'The solution is "YES".',
          },
        ],
      },
      {
        id: 8,
        type: PuzzleType.COUNTER,
        shortTitle: "Break",
        title: "Break",
        description: `You get a 10-minute break to think and rest.
        
        You’ve said the password to the bartender: "Where are you going with my jar of greasy crock?" and in return, you received a note. Take good care of the note.
        Of course, you can take longer than 10 minutes if you like.`,
        images: [require("../../assets/images/president/stopper.jpg")],
        amount: 600,
      },
      {
        id: 9,
        type: PuzzleType.STANDARD,
        shortTitle: "Watery Truth",
        title: `Watery Truth`,
        description: `Search for the year of truth near the Szabó Ervin Library. It might be a bit watery.`,
        images: [require("../../assets/images/pandemic/6.jpg")],
        solutionRegExp: /^(1928)$/,
        solutionKey: "1928",
        hints: [
          {
            id: 0,
            text: "You'll find the inscription on a fountain.",
          },
          {
            id: 1,
            text: "The letter ‘Ő’ kills oppression, its soul revives truth.",
          },
          {
            id: 2,
            text: 'The solution is "1928".',
          },
        ],
      },
      {
        id: 10,
        type: PuzzleType.STANDARD,
        shortTitle: "Above the Stars",
        title: `Above the Stars`,
        description: `Look for the highest star at Kálvin Square. If you subtract 98 from the time of the truth fountain, you'll get the year when the building you're looking for was completed.`,
        images: [require("../../assets/images/pandemic/7.jpg")],
        solutionRegExp: /^(1830)$/,
        solutionKey: "1830",
        hints: [
          {
            id: 0,
            text: "This building is located at Kálvin Square.",
          },
          {
            id: 1,
            text: "John Calvin stands opposite it.",
          },
          {
            id: 2,
            text: 'The solution is "1830".',
          },
        ],
      },
      {
        id: 11,
        type: PuzzleType.STANDARD,
        shortTitle: "Who is the Poet?",
        title: `Who is the Poet?`,
        description: `Go to the highest star at Kálvin Square. It's a building constructed in 1830.
        
        If the clock shows 11:55, you'll find the name of a poet we'll need for the next puzzle.`,
        images: [require("../../assets/images/pandemic/7b.jpg")],
        solutionRegExp: /^(ADY ENDRE)$/,
        solutionKey: "ADY ENDRE",
        hints: [
          {
            id: 0,
            text: "If you're facing the church, head in the direction the hands point to 11:55.",
          },
          {
            id: 1,
            text: "Look at the ground and you'll find the poet.",
          },
          {
            id: 2,
            text: 'The solution is "ADY ENDRE".',
          },
        ],
      },
      {
        id: 12,
        type: PuzzleType.STANDARD,
        shortTitle: "What Are You Looking For?",
        title: `What Are You Looking For?`,
        description: `Using Ady Endre’s poem and the numbers, figure out which theater you need to go to.
        Only focus on the letters! Spaces, slashes, commas, periods, and hyphens don't matter. Double letters count as one.
        
        The numbers are as follows: 1,21,80,14,7, 16,2,7`,
        images: [require("../../assets/images/pandemic/7c.jpg")],
        solutionRegExp: /^(PUDER BAR|PÚDER BÁR|PUDER BÁR|PÚDER BAR)$/,
        solutionKey: "PUDER BAR",
        hints: [
          {
            id: 0,
            text: "Each number represents a letter.",
          },
          {
            id: 1,
            text: "You need to calculate it from Ady’s poem.",
          },
          {
            id: 2,
            text: 'The solution is "PÚDER BÁR".',
          },
        ],
      },
      {
        id: 13,
        type: PuzzleType.STANDARD,
        shortTitle: "What's the Password?",
        title: `What's the Password?`,
        description: `Go to the Púder Bar Theater (Ráday Street 8, 1092). Fortunately, Nikolai has been caught, but he didn't have any antidote with him. However, he remembers less and less. The police found a diary on him filled with all sorts of codes. While they try to crack it, go and have a drink at the new place. Be careful, if anyone asks who you are, make up a lie! Try not to draw too much attention. Make sure to get the notes. You're at the second and final location, so now you can eat and drink in peace. Do you remember the password you got at the previous location? Tell the bartender, and enter it here as the solution after you've received the note from the waiter!`,
        images: [require("../../assets/images/pandemic/jelszo.jpg")],
        solutionRegExp: /^(ELVESZTETTEM A KECSKÉMET|ELVESZTETTEM A KECSKEMET|elvesztettem a kecskémet|elvesztettem a kecskemet|Elvesztettem a kecskémet|Elvesztettem a kecskemet|ELVESZTETTEM A KECSKÈMET|ELVESZTETTEM A KECSKEMET|elvesztettem a kecskèmet|elvesztettem a kecskemet|Elvesztettem a kecskèmet|Elvesztettem a kecskemet)$/,
        solutionKey: "ELVESZTETTEM A KECSKÉMET",
        hints: [
          {
            id: 0,
            text: 'A megoldás "ELVESZTETTEM A KECSKÉMET"',
          },
        ],
      },
      {
        id: 14,
        type: PuzzleType.COUNTER,
        shortTitle: "Púder Bar Theater",
        title: "Púder Bar Theater",
        description: `Make sure to ask for the note, don’t move on without it, and take great care of it! Remember, every move you make is being watched!`,
        images: [require("../../assets/images/pandemic/puder.jpg")],
        amount: 600, // In sec
      },
      {
        id: 15,
        type: PuzzleType.STANDARD,
        shortTitle: "Final Solution",
        title: `Final Solution`,
        description: `The Doctor's associates were caught, and they immediately revealed that the last part of the formula can be found on a glass at Púder, which could save everyone from the impending danger! So, if you haven't ordered yet, wait for the drinks. Do you have any idea what the full solution might be? Look around! If you haven't found the writing, ask the waiter! Do you have all three clues for the formula? (YES/NO)`,
        images: [
          require('../../assets/images/pandemic/final.jpg')
        ],
        solutionRegExp: /^(YES)$/,
        solutionKey: "YES",
        hints: [
          {
            id: 0,
            text: 'The solution is "YES".',
          },
        ],
      },
      {
        id: 16,
        type: PuzzleType.STANDARD,
        shortTitle: "The Formula",
        title: `The Formula`,
        description: `Put the parts of the formula from the first, second, and now the glass in order! Enter it as the solution! Immediately have a drink to rid yourself of the virus!`,
        images: [require("../../assets/images/pandemic/15.jpg")],
        solutionRegExp: /^(H2O\+ALKOHOL)$/,
        solutionKey: "H2O+ALKOHOL",
        hints: [
          {
            id: 0,
            text: "Arrange the clues you received on the notes in order!",
          },
          {
            id: 1,
            text: "It should form a formula!",
          },
          {
            id: 2,
            text: 'The solution is "H2O+ALCOHOL".',
          },
        ],
      },
      {
        id: 17,
        type: PuzzleType.END,
        shortTitle: "Vége",
        title: "Congratulations!",
        description: `You were smart! You found the antidote and, in the process, revealed your true identity. You are also Nikolai's scientists, but due to the nerve agent, you didn’t remember. We'll immediately prepare the antidote and begin spraying it from a helicopter. Since I’ve grown fond of you, you have two more hours to drink, after which we’ll send the police for you. Thank you, and we hope you get out of prison soon so we can play again! Now drink, eat, and enjoy yourselves!`,
        url: "https://detectivetour.hu/",
      },
    ],
  }
};
