import React from "react";
import { useDispatch } from "react-redux";
import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  Box,
} from "@material-ui/core";
import { setUserData } from "../../redux/api/apiActions";
import { I18nService } from "../../services/i18n-service";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      height: "100vh",
      padding: 10
    },
    title: {
      alignSelf: "center"
    },
    label: {
      display: "flex"
    },
    textField: {},
    sendButton: {
      display: "flex"
    },
    alertComponent: {}
  })
);

type LanguageSelectorProp = {
  onComplete: Function;
};

const LanguageSelector: React.FC<LanguageSelectorProp> = ({ onComplete }) => {
  const classes = useStyles({});
  const dispatch = useDispatch();

  const onSelectLanguage = (language: string) => {
    new I18nService().setLanguage(language);
    onComplete();
  };

  const languages = new I18nService().getPossibleLanguages().map((language) => {
    return <div className="language-holder" onClick={() => {
      onSelectLanguage(language.language);
    }}>
      <img src="{{language.image}}" alt="" />
    </div>;
  });

  return (
    <Box className={classes.root}>
      <Typography
        className={classes.title}
        variant="h5"
        color="primary"
        component="h2"
      >
        Nyelv / Language
      </Typography>
      <div className="language-selector">
        <div className="language-holder" onClick={() => {
          onSelectLanguage('hu');
        }}>
          <img src="https://vectorflags.s3.amazonaws.com/flags/hu-circle-01.png" alt="" />
        </div>
        <div className="language-holder" onClick={() => {
          onSelectLanguage('en');
        }}>
          <img src="https://vectorflags.s3.amazonaws.com/flags/uk-circle-01.png" alt="" />
        </div>
      </div>
    </Box>
  );
};

export default LanguageSelector;
