import {
  API_SESSION_FETCH,
  API_SOLVE_PUZZLE,
  API_LOG_OUT,
  API_LOG_ANSWER,
  API_EXECUTE_MUTATION
} from "./apiActionTypes";

import { INCREMENT_HINT_COUNT, INCREMENT_MISSED_COUNT, SET_FINISHED_AT, SET_USER_DATA } from "./queries";
import { dateToIsoDate } from "../../utils/isoTime";

export const fetchSession = (voucherCode: String) => ({
  type: API_SESSION_FETCH,
  payload: {
    voucherCode: voucherCode
  }
});

export const loadSession = () => ({
  type: API_SESSION_FETCH
});

export const solvePuzzle = () => ({
  type: API_SOLVE_PUZZLE
});

export const logAnswer = answer => ({
  type: API_LOG_ANSWER,
  payload: {
    answer: answer
  }
});

export const setUserData = data => ({
  type: API_EXECUTE_MUTATION,
  payload: {
    mutation: SET_USER_DATA,
    variables: {
      "numberOfPlayers": data.playerCount,
      "email": data.email,
      "address": data.address,
      "taxNumber": data.taxNumber,
      "startedAt": dateToIsoDate(new Date()),
      "hasNewsletter": data.hasNewsletter,
    }
  }
});

export const setEndTime = () => ({
  type: API_EXECUTE_MUTATION,
  payload: {
    mutation: SET_FINISHED_AT,
    variables: {
      "finishedAt": dateToIsoDate(new Date())
    }
  }
});

export const increaseHintCount = () => ({
  type: API_EXECUTE_MUTATION,
  payload: {
    mutation: INCREMENT_HINT_COUNT,
  }
});

export const increaseMissedCount = () => ({
  type: API_EXECUTE_MUTATION,
  payload: {
    mutation: INCREMENT_MISSED_COUNT,
  }
});

export const logOut = () => ({
  type: API_LOG_OUT
});
