import { Tour, PuzzleType } from "./types";

export const demoTour: Tour = {
  id: 0,
  name: "Teszt tour",
  description: "",
  puzzles: [
    {
      id: 0,
      type: PuzzleType.START
    },
    {
      id: 1,
      type: PuzzleType.GPS_TRACKER,
      shortTitle: '1. etap',
      title: '1. etap',
      description: `Menj az alábbi helyre: Nyugati téri óra.`,
      destination: {
        lat: 47.5097950936942,
        lng: 19.056287800285226,
        distance: 30
      }
    },
    {
      id: 2,
      type: PuzzleType.STANDARD,
      shortTitle: '2. etap',
      title: '2. etap',
      description: `Gratulálunk, elérkeztél a 0-s kilométerkőhöz. Látod a szobrot?
      
      IGEN/NEM`,
      solutionKey: 'IGEN',
      solutionRegExp: /^(Igen|igen|IGEN)$/,
    },
    {
      id: 3,
      type: PuzzleType.END,
      title: 'Végeztél',
      description: 'Gratulálok, elérkeztél a 0-s kilométerkőhöz.'
    }
  ]
};
