import React, { useState } from 'react';
import ReactDOM from 'react-dom';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button, Dialog, makeStyles, Theme, createStyles } from '@material-ui/core';
import Img from 'react-image'
import { I18nService } from '../services/i18n-service';

type AlertProps = {
  title: string,
  description: string,
  imgUrl?: any,
  onCancel?: () => void,
  onConfirm?: () => void
}

const defaultAlertProps: AlertProps = {
  title: "NaN",
  description: "Nan",
};

type AlertComponentProps = AlertProps & {
  isOpen: boolean,
  hideAlert: () => void,
  className?: string,
}

const defaultAlertComponentProps: AlertComponentProps = {
  ...defaultAlertProps,
  isOpen: false,
  hideAlert: () => { }
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    img: {
      width: "200px",
    }
  }),
)

export const Alert: React.FC<AlertComponentProps> = (
  {
    className,
    title,
    description,
    imgUrl,
    onCancel,
    onConfirm,
    isOpen,
    hideAlert
  }
) => {

  const classes = useStyles({});

  const handleClose = (isConfirmed: boolean) => {
    hideAlert();

    if (isConfirmed) {
      onConfirm && onConfirm();
    } else {
      onCancel && onCancel();
    }
  }

  return ReactDOM.createPortal(
    <Dialog
      className={className}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>
        {imgUrl && <Img src={imgUrl} className={classes.img}/>}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(false)} color="primary">
          {new I18nService().getTranslationFor('close')}
          </Button>
        {onConfirm && // Render if has onConfirmed defined
          <Button onClick={() => handleClose(true)} color="primary" autoFocus>
            Segítség
            </Button>
        }
      </DialogActions>
    </Dialog>, document.body
  );
}

export const useAlert = () => {
  const [alertComponentProps, setAlertComponentProps] = useState(defaultAlertComponentProps);

  const showAlert = (_alertProps: AlertProps) => {

    // Store as const to avoid re-render on isOpen:false
    const newProps = {
      ...defaultAlertComponentProps,
      ..._alertProps,
      isOpen: true,
    }

    setAlertComponentProps({
      ...newProps,
      hideAlert: () => {
        setAlertComponentProps({
          ...newProps,
          isOpen: false
        })
      }
    })
  }

  return {
    showAlert, alertComponentProps
  }
};