import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';

type ProgressBarProps = {
    value: number
    max: number
}

const BorderLinearProgress = withStyles({
    root: {
      height: 10,
      backgroundColor: '#000'
    },
    bar: {
      borderRadius: 20,
      backgroundColor: '#ff6c5c',
    },
  })(LinearProgress);

const ProgressBar: React.FC<ProgressBarProps> = ({
    value,
    max
}: ProgressBarProps) => {
    return (
        <Box height='100%'>
            <BorderLinearProgress
                variant="determinate"
                color="secondary"
                value={value/max*100}
            />
        </Box>
    );
}

export default ProgressBar;